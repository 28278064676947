import { Component, OnInit } from '@angular/core';
import { MediaService } from './media.service';
import { ApiResponse } from 'src/app/shared/interfaces/api-response.interface';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { Pagination } from 'src/app/shared/interfaces/pagination.interface';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss']
})
export class MediaComponent implements OnInit {

  filter: any = {
    keyword: null,
    category: null
  }
  pagination: Pagination;
  sortBy: string = "created_at";
  orderBy: string = "desc";

  constructor(
    public _dataService: MediaService,
    private _utilityService: UtilityService,
    private _loadingService: LoadingService,
    public _fileUploadService: FileUploadService,
    private _activatedRoute: ActivatedRoute
  ) {
    this._activatedRoute.params.subscribe(params => {
      this.pagination = _utilityService.pagination;
      this.filter.category = params['category'];
      this.getDataList();
    });
  }

  ngOnInit(): void {
  }

  /**
    * Get next page data
    * 
    * @param page 
    */
  getPagination(event: any) {
    this.pagination = event;
    this.getDataList();
  }

  /**
   * Get Data List with pagination
   */
  async getDataList() {
    try {
      this._loadingService.loading.next(true);
      const response: ApiResponse = await this._dataService.search(
        this.filter,
        this.pagination.perPage,
        this.pagination.currentPage,
        this.sortBy,
        this.orderBy
      );

      this.pagination = response.data;
    } catch (error) {
      console.error(error);
    } finally {
      this._loadingService.loading.next(false);
    }
  }

}
