import { Component, Input, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from '../../product.service';

@Component({
  selector: 'app-new-product-request',
  templateUrl: './new-product-request.component.html',
  styleUrls: ['./new-product-request.component.scss']
})
export class NewProductRequestComponent {

  modal = inject(NgbActiveModal);

  @Input() data: any;

  formGroup!: FormGroup;

  constructor(
    private _dataService: ProductService
  ) {
    this.formGroup = this._dataService.createFormForNewProductRequest();
  }

  onSubmit() {
    const data = this.formGroup.getRawValue();
    this._dataService.sendNewProductRequest(data, this.data.id, this.modal);
  }
}
