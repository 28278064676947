<div class="pagination-area">
    <a (click)="pagination.currentPage !== 1 && onPageChange(1)" class="prev page-numbers"><i
            class="fas fa-angle-double-left"></i></a>
    <a (click)="pagination.currentPage !== 1 && onPageChange(pagination.currentPage - 1)" class="prev page-numbers"><i
            class="fas fa-angle-left"></i></a>
    <a *ngFor="let page of getPages(pagination.totalPages)" [ngClass]="{ 'current': pagination.currentPage === page }"
        class="page-numbers" aria-current="page" (click)="onPageChange(page)">{{ page }}</a>
    <a (click)="pagination.currentPage !== pagination.totalPages && onPageChange(pagination.currentPage + 1)"
        class="next page-numbers"><i class="fas fa-angle-right"></i></a>
    <a (click)="onPageChange(pagination.totalPages)" class="next page-numbers"><i
            class="fas fa-angle-double-right"></i></a>
</div>