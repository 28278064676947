import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse, HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { config } from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  defaultUserImage: string = 'assets/images/dummy/user.png';
  defaultLogoImage: string = 'assets/images/logo.jpeg';
  defaultBannerImage: string = 'assets/images/dummy/banner.png';

  constructor(
    private _httpClient: HttpClient,
    private _apiService: ApiService
  ) { }

  /**
   * Upload Image
   * 
   * @param formData 
   */
  uploadImage(formData: any): Observable<any> {
    return this._httpClient.post(`${environment.apiUrl}file/upload/public`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      catchError(this.errorMgmt)
    )
  }

  /**
   * Error management
   * 
   * @param error 
   */
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

  /**
   * Delete File
   * 
   * */
  delete(file: any) {
    return new Promise((resolve, reject) => {
      this._httpClient.delete(`${environment.apiUrl}file/delete/${file}`)
        .subscribe((response: any) => {
          resolve(response);
        }, reject);
    });
  }

  /**
   * Upload multiple files
   * 
   * @param data 
   */
  uploadMultiple(data: any) {
    return this._apiService.post(data, 'file/uploadMultiple');
  }


  /**
   * Get image
   * 
   * @param type 
   * @param file 
   */
  getImage(type: any, file: any) {
    return this._apiService.get(`file/image/${type}/${file}`);
  }

  /**
   * Download file
   * 
   * @param type 
   * @param file 
   */
  downloadFile(type: any, file: any) {
    return this._apiService.get(`file/downloadFile/${type}/${file}`);
  }

  /**
  * Get File Url
  * 
  * @param file 
  * @param type 
  */
  getFileUrl(file: any, type?: any): any {
    let url = config.apiUrl + 'image/';
    if (file && file !== '') {
      url += file;
    } else if (type && type === 'user') {
      url = this.defaultUserImage;
    } else if (type && type === 'logo') {
      url = this.defaultLogoImage;
    } else if (type && type === 'banner') {
      url = this.defaultBannerImage;
    } else {
      url = this.defaultLogoImage;
    }
    return url;
  }

  onImgError(event: any, type: any) {
    let url = '';
    if (type && type === 'user') {
      url = this.defaultUserImage;
    } else if (type && type === 'logo') {
      url = this.defaultLogoImage;
    } else if (type && type === 'banner') {
      url = this.defaultBannerImage;
    } else {
      url = this.defaultLogoImage;
    }
    event.target.src = url;

  }

  download(response: HttpResponse<Blob>): void {
    const contentDispositionHeader = response.headers.get('Content-Disposition');
    const fileName = this.getFileNameFromContentDisposition(contentDispositionHeader);

    const blob = new Blob([response.body as BlobPart], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.setAttribute('download', fileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  private getFileNameFromContentDisposition(contentDisposition: string | null): string {
    let fileName = 'example.xlsx';
    if (contentDisposition) {
      const matches = contentDisposition.split(';')[1].trim().split('=')[1];
      if (matches && matches.length > 0) {
        fileName = matches.replace(/["']/g, '');
      }
    }
    return fileName;
  }
}