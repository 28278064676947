import { Injectable } from '@angular/core';
import { API_CONSTANTS } from 'src/app/shared/config/api-config';
import { ApiService } from 'src/app/shared/services/api.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Pagination } from 'src/app/shared/interfaces/pagination.interface';

@Injectable({
    providedIn: 'root'
})
export class MediaService implements Resolve<any> {

    categories: BehaviorSubject<any> = new BehaviorSubject([]);
    onDataChanged: BehaviorSubject<any> = new BehaviorSubject({});
    onDataListChanged: BehaviorSubject<Pagination> = new BehaviorSubject<Pagination>({} as Pagination);
    data: any = null;
    routeParams: any = null;
    state: string = "";

    /**
     * Initialize constructor
     * 
     * @param _utilityService 
     * @param _apiService 
     * @param _formBuilder 
     * @param _loadingService 
     * @param _router 
     * @param _toastService 
     */
    constructor(
        public _utilityService: UtilityService,
        private _apiService: ApiService
    ) { }

    /**
    * Resolve
    * 
    * @param route 
    * @param state 
    * @returns 
    */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;
        this.state = state.url;
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getDataForResolve()
            ]).then(
                () => resolve(0),
                reject
            );
        });
    }

    /**
     * Get data for resolve
     * 
     * @returns 
     */
    getDataForResolve(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.routeParams.abbreviation) {
                const dataRequest = this.getDataByAbbreviation(this.routeParams.abbreviation);
                dataRequest.then(
                    (response: any) => {
                        this.data = response.data;
                        this.onDataChanged.next(this.data);
                        resolve(response);
                    },
                    reject
                );
            }
        });
    }

    /**
     * Get all categories
     * 
     * @returns 
     */
    getCategories(): Promise<any> {
        return this._apiService.get(API_CONSTANTS.GET_MEDIA_CATEGORY_LIST);
    }

    search(data: any, limit: any, page: any, sortBy?: any, orderBy?: any) {
        let endPoint = API_CONSTANTS.SEARCH_MEDIA;
        endPoint += `?category=${data.category}`;
        if (data.keyword != null && data.keyword !== "") {
            endPoint += `&search=${data.keyword}`;
        }
        if (limit) {
            endPoint += `&limit=${limit}&page=${page}`;
        }
        return this._apiService.get(endPoint);
    }

    /**
    * Get data by id
    * 
    * @param id 
    */
    getDataByAbbreviation(abbreviation: any): Promise<any> {
        return this._apiService.get(API_CONSTANTS.GET_MEDIA_DETAILS_BY_ABBREVIATION.replace("{abbreviation}", abbreviation));
    }

    getLatestData() {
        return this._apiService.get(API_CONSTANTS.GET_LATEST_MEDIA);
    }

}
