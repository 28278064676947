<div class="modal-header d-flex justify-content-between">
    <h4 class="modal-title" id="modal-basic-title">{{data.model}}</h4>
    <button type="button" class="close btn btn-rounded m-1" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true" style="font-size: 35px; color: black;">&times;</span>
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="needs-validation" novalidate>

        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="name">Name</label>
                    <input id="name" class="form-control" formControlName="name" type="text">
                    <div *ngIf="formGroup.get('name').invalid && (formGroup.get('name').dirty || formGroup.get('name').touched)"
                        class="alert alert-danger mt-2">
                        <div *ngIf="formGroup.get('name').errors.required">
                            Name is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="mobileNumber">Mobile Number</label>
                    <input id="mobileNumber" class="form-control" formControlName="mobileNumber" type="tel">
                    <div *ngIf="formGroup.get('mobileNumber').invalid && (formGroup.get('mobileNumber').dirty || formGroup.get('mobileNumber').touched)"
                        class="alert alert-danger mt-2">
                        <div *ngIf="formGroup.get('mobileNumber').errors.required">
                            Mobile number is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="form-group">
                    <label for="budget">Budget</label>
                    <input id="budget" class="form-control" formControlName="budget" type="text">
                    <div *ngIf="formGroup.get('budget').invalid && (formGroup.get('budget').dirty || formGroup.get('budget').touched)"
                        class="alert alert-danger mt-2">
                        <div *ngIf="formGroup.get('budget').errors.required">
                            Budget is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="form-group">
                    <label for="location">Location</label>
                    <input id="location" class="form-control" formControlName="location" type="text">
                    <div *ngIf="formGroup.get('location').invalid && (formGroup.get('location').dirty || formGroup.get('location').touched)"
                        class="alert alert-danger mt-2">
                        <div *ngIf="formGroup.get('location').errors.required">
                            Location is required.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="text-center mt-1">
            <button type="submit" class="btn btn-primary btn-block" [disabled]="formGroup.invalid">Call Now</button>
        </div>

        <div class="text-center mt-3">
            <p>By clicking on Submit, you agree to our <a href="#">terms and conditions</a>.</p>
        </div>
    </form>
</div>