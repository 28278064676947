<div class="modal-body text-center position-relative">
    <!-- Close button positioned in the top right corner -->
    <button type="button" class="close btn btn-rounded position-absolute" aria-label="Close"
        style="top: 10px; right: 10px;" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true" style="font-size: 35px; color: black;">&times;</span>
    </button>
    <div class="mb-3">
        <img src="assets/images/righticon.webp" alt="Check Mark" class="img-fluid">
    </div>
    <p>Your request for your Massey Ferguson 1035 DI Planetary Plus has been received.</p>
    <hr>
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <p class="font-weight-bold">Why do you want to sell your tractor?</p>
        <div class="form-check">
            <input class="form-check-input" type="radio" formControlName="reason" id="reason1"
                value="Looking for a used tractor alternative">
            <label class="form-check-label" for="reason1">
                Looking for a used tractor alternative
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" formControlName="reason" id="reason2"
                value="Want to buy a new tractor">
            <label class="form-check-label" for="reason2">
                Want to buy a new tractor
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" formControlName="reason" id="reason3"
                value="Need instant cash">
            <label class="form-check-label" for="reason3">
                Need instant cash
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" formControlName="reason" id="reason4"
                value="Costly maintenance and ongoing repair">
            <label class="form-check-label" for="reason4">
                Costly maintenance and ongoing repair
            </label>
        </div>
        <div class="text-center">
            <button type="submit" class="btn btn-primary mt-3 full-width" [disabled]="formGroup.invalid">SUBMIT</button>
        </div>
    </form>
</div>