import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiResponse } from 'src/app/shared/interfaces/api-response.interface';
import { Pagination } from 'src/app/shared/interfaces/pagination.interface';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { TractorService } from '../tractor.service';
import { NewTractorsRequestComponent } from './new-tractors-request/new-tractors-request.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstantService } from 'src/app/shared/services/constant.service';

@Component({
  selector: 'app-new-tractors',
  templateUrl: './new-tractors.component.html',
  styleUrls: ['./new-tractors.component.scss']
})
export class NewTractorsComponent implements OnInit {

  filter: any = {
    brand: null,
    price: null,
    hp: null
  }
  pagination: Pagination;
  sortBy: string = "created_at";
  orderBy: string = "desc";
  brands: any[] = [];

  constructor(
    public _dataService: TractorService,
    private _utilityService: UtilityService,
    private _loadingService: LoadingService,
    public _fileUploadService: FileUploadService,
    private _activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    public _constantService: ConstantService
  ) {
    _dataService.brands.subscribe((response: any) => {
      if (response) {
        this.brands = response;
      }
    })
    this._activatedRoute.params.subscribe(params => {
      this.pagination = _utilityService.pagination;
      this.filter.brand = params['brand'];
      this.getDataList();
    });
  }

  ngOnInit(): void {
  }

  /**
  * Get next page data
  * 
  * @param page 
  */
  getPagination(event: any) {
    this.pagination = event;
    this.getDataList();
  }

  /**
   * Get Data List with pagination
   */
  async getDataList() {
    try {
      this._loadingService.loading.next(true);
      const response: ApiResponse = await this._dataService.searchNewTractors(
        this.filter,
        this.pagination.perPage,
        this.pagination.currentPage,
        this.sortBy,
        this.orderBy
      );

      this.pagination = response.data;
    } catch (error) {
      console.error(error);
    } finally {
      this._loadingService.loading.next(false);
    }
  }
}
