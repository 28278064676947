<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Sell Used Implements</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Sell Used Implements</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row implement-form-section m-0 p-0">
    <img src="../../../../../assets/images/tractor/sell-product.jpg" alt="">
    <div class="sell-used-form-container col-lg-6 col-md-6 col-sm-8 m-auto">
        <div class="bg-light border shadow rounded mt-5 implement-form p-3">
            <div class="card-header">
                <ul class="nav nav-pills nav-justified">
                    <ng-container *ngFor="let step of steps; let i = index">
                        <li class="nav-item">
                            <a class="nav-link" [class.active]="currentStep === i" (click)="goToStep(i)">
                                <div class="step-icon">
                                    <i [class]="getIconClass(i)"></i>
                                </div>
                                <div class="step-label">{{ step }}</div>
                            </a>
                        </li>
                    </ng-container>
                </ul>
            </div>

            <div class="card-body">
                <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
                    <div [ngSwitch]="currentStep">
                        <div *ngSwitchCase="0" formGroupName="step1">
                            <div>
                                <div class="form-group mt-4">
                                    <label for="categoryId">Category</label>
                                    <select id="categoryId" class="form-control" formControlName="categoryId">
                                        <option value="" disabled>Select a category</option>
                                        <option *ngFor="let brand of categories" [value]="brand.id">{{ brand.name }}
                                        </option>
                                    </select>
                                    <div *ngIf="formGroup.get('step1.categoryId').invalid && formGroup.get('step1.categoryId').touched"
                                        class="text-danger">
                                        Category is required.
                                    </div>
                                </div>
                                <div class="form-group mt-4">
                                    <label for="brandId">Brand</label>
                                    <select id="brandId" class="form-control" formControlName="brandId">
                                        <option value="" disabled>Select a brand</option>
                                        <option *ngFor="let brand of brands" [value]="brand.id">{{ brand.name }}
                                        </option>
                                    </select>
                                    <div *ngIf="formGroup.get('step1.brandId').invalid && formGroup.get('step1.brandId').touched"
                                        class="text-danger">
                                        Brand is required.
                                    </div>
                                </div>
                                <div class="form-group mt-4">
                                    <label for="model">Model</label>
                                    <input id="model" type="text" class="form-control" formControlName="model"
                                        placeholder="Enter model">
                                    <div *ngIf="formGroup.get('step1.model').invalid && formGroup.get('step1.model').touched"
                                        class="text-danger">
                                        Model is required.
                                    </div>
                                </div>
                                <div class="form-group mt-4">
                                    <label for="year">Year</label>
                                    <select id="year" class="form-control" formControlName="year">
                                        <option value="" disabled>Select a year</option>
                                        <option *ngFor="let item of _constantService.years" [value]="item">{{
                                            item }}
                                        </option>
                                    </select>
                                    <div *ngIf="formGroup.get('step1.year').invalid && formGroup.get('step1.year').touched"
                                        class="text-danger">
                                        Year is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase="1" formGroupName="step2">
                            <div>
                                <div class="form-group mt-4">
                                    <label for="title">Title</label>
                                    <input id="title" type="text" class="form-control" formControlName="title"
                                        placeholder="Enter title">
                                    <div *ngIf="formGroup.get('step2.title').invalid && formGroup.get('step2.title').touched"
                                        class="text-danger">
                                        Title is required.
                                    </div>
                                </div>
                                <div class="form-group mt-4">
                                    <label for="price">Price</label>
                                    <div class="input-group">
                                        <input id="price" type="text" class="form-control" formControlName="price"
                                            placeholder="Enter price">
                                        <div class="input-group-append">
                                            <span class="input-group-text">₹</span>
                                        </div>
                                    </div>
                                    <div *ngIf="formGroup.get('step2.price').invalid && formGroup.get('step2.price').touched"
                                        class="text-danger">
                                        Price is required.
                                    </div>
                                </div>

                                <div class="form-group mt-4">
                                    <label for="description">Description</label>
                                    <textarea id="description" class="form-control" formControlName="description"
                                        placeholder="Enter description"></textarea>
                                    <div *ngIf="formGroup.get('step2.description').invalid && formGroup.get('step2.description').touched"
                                        class="text-danger">
                                        Description is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase="2" formGroupName="step3">
                            <div>
                                <label for="images">Images</label>
                                <app-upload-images [files]="images" [type]="'tractors'"
                                    (outputFile)="getFiles($event)"></app-upload-images>
                                <div *ngIf="formGroup.get('step3.images').invalid && formGroup.get('step3.images').touched"
                                    class="text-danger">
                                    Images are required.
                                </div>
                            </div>
                        </div>
                        <div *ngSwitchCase="3" formGroupName="step4">
                            <div>
                                <div class="form-group mt-4">
                                    <label for="name">Name</label>
                                    <input id="name" type="text" class="form-control" formControlName="name"
                                        placeholder="Enter your name">
                                    <div *ngIf="formGroup.get('step4.name').invalid && formGroup.get('step4.name').touched"
                                        class="text-danger">
                                        Name is required.
                                    </div>
                                </div>
                                <div class="form-group mt-4">
                                    <label for="mobileNumber">Mobile Number</label>
                                    <input id="mobileNumber" type="text" class="form-control"
                                        formControlName="mobileNumber" placeholder="Enter mobile number">
                                    <div *ngIf="formGroup.get('step4.mobileNumber').invalid && formGroup.get('step4.mobileNumber').touched"
                                        class="text-danger">
                                        Valid Mobile Number is required.
                                    </div>
                                </div>
                                <div class="form-group mt-4">
                                    <label for="state">State</label>
                                    <input id="state" type="text" class="form-control" formControlName="state"
                                        placeholder="Enter state">
                                    <div *ngIf="formGroup.get('step4.state').invalid && formGroup.get('step4.state').touched"
                                        class="text-danger">
                                        State is required.
                                    </div>
                                </div>
                                <div class="form-group mt-4">
                                    <label for="district">District</label>
                                    <input id="district" type="text" class="form-control" formControlName="district"
                                        placeholder="Enter district">
                                    <div *ngIf="formGroup.get('step4.district').invalid && formGroup.get('step4.district').touched"
                                        class="text-danger">
                                        District is required.
                                    </div>
                                </div>
                                <div class="form-group mt-4">
                                    <label for="tehsil">Tehsil</label>
                                    <input id="tehsil" type="text" class="form-control" formControlName="tehsil"
                                        placeholder="Enter tehsil">
                                    <div *ngIf="formGroup.get('step4.tehsil').invalid && formGroup.get('step4.tehsil').touched"
                                        class="text-danger">
                                        Tehsil is required.
                                    </div>
                                </div>
                                <div class="form-group mt-4">
                                    <label for="pincode">Pincode</label>
                                    <input id="pincode" type="text" class="form-control" formControlName="pincode"
                                        placeholder="Enter pincode">
                                    <div *ngIf="formGroup.get('step4.pincode').invalid && formGroup.get('step4.pincode').touched"
                                        class="text-danger">
                                        Pincode is required.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <button type="button" class="btn btn-secondary" (click)="previousStep()"
                            *ngIf="currentStep > 0">Previous</button>
                        &nbsp;
                        <button type="button" class="btn btn-primary" (click)="nextStep()" *ngIf="currentStep === 0"
                            [disabled]="formGroup.get('step1').invalid">Next</button>
                        <button type="button" class="btn btn-primary" (click)="nextStep()" *ngIf="currentStep === 1"
                            [disabled]="formGroup.get('step2').invalid">Next</button>
                        <button type="button" class="btn btn-primary" (click)="nextStep()" *ngIf="currentStep === 2"
                            [disabled]="formGroup.get('step3').invalid">Next</button>
                        <button type="submit" class="btn btn-success" *ngIf="currentStep === 3">Submit</button>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>


<section class="update-section inner-update-section ptb-100">
    <div class="container">
        <div class="update-title">
            <h3>Get Every Single Update</h3>
        </div>

        <form class="update-form">
            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
            <button type="submit">Subscribe Newsletter</button>
        </form>
    </div>

    <div class="shape"><img src="assets/images/shape/shape19.png" alt="Images"></div>
    <div class="shape-2"><img src="assets/images/shape/shape20.png" alt="Images"></div>
</section>