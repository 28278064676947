export const API_CONSTANTS = {
    //User
    ADMIN_LOGIN: "auth/login",
    USER_LOGIN: "auth/login",
    FORGOT_PASSWORD: 'user/password/forgot',
    CHANGE_PASSWORD: 'user/password/change',
    DEACTIVATE_USER: `users/{id}/deactivate`,
    ACTIVATE_USER: `users/{id}/activate`,
    GET_USER_DETAILS_BY_ID: `users/{id}/details`,
    GET_USER_BY_ID: `users/{id}`,
    SEARCH_USERS: `users/search`,
    REQUEST_FOR_FORGOT_PASSWORD: `user/request/password/reset?email={email}`,
    UPDATE_USER: `users/{id}/update`,
    ADD_ADMIN: `admin/add`,
    ADD_MANAGER: `manager/add`,

    //Category
    GET_ALL_CATEGORY: `categories/getAll`,
    GET_CATEGORY_LIST: `categories/list`,
    UPDATE_CATEGORY: `categories/{id}/update`,
    DELETE_CATEGORY: `categories/{id}/delete`,
    ADD_CATEGORY: `categories/add`,

    //Style
    UPDATE_STYLE: `styles/{id}/update`,
    DELETE_STYLE: `styles/{id}/delete`,
    GET_STYLES_BY_CATEGORY_ID: `styles/category/{categoryId}`,
    ADD_STYLE: `styles/add`,

    //Subcategory
    UPDATE_SUBCATEGORY: `subCategories/{id}/update`,
    DELETE_SUBCATEGORY: `subCategories/{id}/delete`,
    GET_SUB_CATEGORIES_BY_STYLE_ID: `subCategories/style/{styleId}`,
    ADD_SUBCATEGORY: `subCategories/add`,

    //Order
    GET_ORDER_DETAILS_BY_ID: `orders/get/{id}`,
    GET_ORDER_BY_ID: `orders/{id}`,
    SEARCH_ORDERS: `orders/admin/search`,
    ADD_SHIPPING: `orders/{id}/shipping`,
    DELIVER_ORDER: `orders/{id}/deliver`,
    CANCEL_ORDER: `orders/{id}/cancel`,
    MARK_AS_A_GIFT: `orders/{id}/markAsAGift`,

    //Language
    DEACTIVATE_LANGUAGE: `languages/{id}/inactive`,
    ACTIVATE_LANGUAGE: `languages/{id}/active`,
    GET_LANGUAGE_DETAILS_BY_ID: `languages/{id}/details`,
    GET_LANGUAGE_BY_ID: `languages/{id}`,
    DELETE_LANGUAGE_BY_ID: `languages/{id}/delete`,
    SEARCH_LANGUAGES: `languages/search`,
    EXPORT_LANGUAGES: `languages/export`,
    UPDATE_LANGUAGE: `languages/{id}/update`,
    ADD_LANGUAGE: `languages/add`,
    GET_ALL_LANGUAGE: 'languages/getAll',

    //Country
    DEACTIVATE_COUNTRY: `countries/{id}/inactive`,
    ACTIVATE_COUNTRY: `countries/{id}/active`,
    GET_COUNTRY_DETAILS_BY_ID: `countries/{id}/details`,
    GET_COUNTRY_BY_ID: `countries/{id}`,
    SEARCH_COUNTRIES: `countries/search`,
    EXPORT_COUNTRIES: `countries/export`,
    UPDATE_COUNTRY: `countries/{id}/update`,
    ADD_COUNTRY: `countries/add`,
    DELETE_COUNTRY_BY_ID: `countries/{id}/delete`,
    GET_LANGUAGES_BY_COUNTRY_ID: `countries/{id}/languages`,
    CHANGE_COUNTRY_STATUS: `countries/{id}/status/change`,
    GET_ALL_COUNTRY: 'countries/getAll',

    //Promo Code
    DEACTIVATE_PROMO_CODE: `promoCodes/{id}/inactive`,
    ACTIVATE_PROMO_CODE: `promoCodes/{id}/active`,
    GET_PROMO_CODE_DETAILS_BY_ID: `promoCodes/{id}/details`,
    GET_PROMO_CODE_BY_ID: `promoCodes/{id}`,
    DELETE_PROMO_CODE_BY_ID: `promoCodes/{id}/delete`,
    SEARCH_PROMO_CODES: `promoCodes/search`,
    EXPORT_PROMO_CODES: `promoCodes/export`,
    UPDATE_PROMO_CODE: `promoCodes/{id}/update`,
    ADD_PROMO_CODE: `promoCodes/add`,
    GET_ALL_PROMO_CODE: 'promoCodes/getAll',

    //Shipping Company
    GET_ALL_SHIPPING_COMPANY: `shipping/companies/getAll`,
    GET_SHIPPING_COMPANY_LIST: `shipping/companies/list`,
    UPDATE_SHIPPING_COMPANY: `shipping/companies/{id}/update`,
    DELETE_SHIPPING_COMPANY: `shipping/companies/{id}/delete`,
    ADD_SHIPPING_COMPANY: `shipping/companies/add`,
    SEARCH_SHIPPING_COMPANIES: `shipping/companies/search`,

    //Product content
    GET_PRODUCT_CONTENT_BY_ID: `product/contents/{id}`,
    DELETE_PRODUCT_CONTENT_BY_ID: `product/contents/{id}/delete`,
    SEARCH_PRODUCT_CONTENTS: `product/contents/search`,
    UPDATE_PRODUCT_CONTENT: `product/contents/{id}/update`,
    ADD_PRODUCT_CONTENTS: `product/contents/add`,
    GET_ALL_PRODUCT_CONTENTS: `product/contents/getAll`,
    GET_PRODUCT_CONTENT_BY_TITLE: `product/contents/title/{title}`,

    //content
    GET_CONTENT_BY_ID: `contents/{id}`,
    DELETE_CONTENT_BY_ID: `contents/{id}/delete`,
    SEARCH_CONTENTS: `contents/search`,
    UPDATE_CONTENT: `contents/{id}/update`,
    ADD_CONTENTS: `contents/add`,
    GET_ALL_CONTENTS: `contents/getAll`,
    GET_CONTENT_BY_TITLE: `contents/title/{title}`,

    //////////////////////Media Categories/////////////////////////
    ADD_MEDIA_CATEGORY: `media/categories/add`,
    SEARCH_MEDIA_CATEGORY: `media/categories/search`,
    UPDATE_MEDIA_CATEGORY: `media/categories/{id}/update`,
    GET_MEDIA_CATEGORY_LIST: `media/categories`,

    //////////////////////Media/////////////////////////
    ADD_MEDIA: `media/add`,
    SEARCH_MEDIA: `public/media/search`,
    UPDATE_MEDIA: `media/{id}/update`,
    GET_MEDIA_CATEGORY: `media`,
    GET_MEDIA_DETAILS_BY_ABBREVIATION: `public/media/{abbreviation}`,
    GET_LATEST_MEDIA: `public/media/latest`,

    //////////////////////Tractor Brands/////////////////////////
    ADD_TRACTOR_BRAND: `tractor/brands/add`,
    SEARCH_TRACTOR_BRANDS: `tractor/brands/search`,
    UPDATE_TRACTOR_BRAND: `tractor/brands/{id}/update`,
    GET_TRACTOR_BRAND_LIST: `tractor/brands`,
    GET_TRACTOTR_BRAND_BY_ID: `tractor/brands/{id}`,

    //////////////////////Tractor/////////////////////////
    ADD_TRACTOR: `tractors/add`,
    SEARCH_NEW_TRACTOR: `tractors/search/public`,
    SEARCH_USED_TRACTOR: `used/tractors/search/public`,
    UPDATE_TRACTOR: `tractors/{id}/update`,
    GET_NEW_TRACTOR_DETAILS_BY_ID: `tractors/{abbreviation}/details/public`,
    GET_USED_TRACTOR_DETAILS_BY_ABBREVIATION: `used/tractors/{abbreviation}/details/public`,
    NEW_TRACTOR_REQUEST: `new/tractors/{id}/request/send`,
    USED_TRACTOR_REQUEST: `used/tractors/{id}/request/send`,
    SELL_USED_TRACTOR: `used/tractors/add`,

    //////////////////////Product/////////////////////////
    ADD_PRODUCT: `products/add`,
    SEARCH_PRODUCTS: `products/search`,
    SEARCH_NEW_PRODUCTS: `products/search/public`,
    SEARCH_USED_PRODUCTS: `used/products/search/public`,
    UPDATE_PRODUCT: `products/{id}/update`,
    GET_PRODUCT_CATEGORY: `products`,
    GET_PRODUCT_BY_ID: `products/{id}`,
    GET_NEW_PRODUCT_DETAILS_BY_ID: `products/{abbreviation}/details/public`,
    GET_USED_PRODUCT_DETAILS_BY_ABBREVIATION: `used/products/{abbreviation}/details/public`,
    NEW_PRODUCT_REQUEST: `new/products/{id}/request/send`,
    USED_PRODUCT_REQUEST: `used/products/{id}/request/send`,
    SELL_USED_PRODUCT: `used/products/add`,

    //////////////////////Product Brands/////////////////////////
    ADD_PRODUCT_BRAND: `product/brand/add`,
    SEARCH_PRODUCT_BRANDS: `product/brands/search`,
    UPDATE_PRODUCT_BRAND: `product/brands/{id}/update`,
    GET_PRODUCT_BRANDS: `product/brands`,
    GET_PRODUCT_BRAND_BY_ID: `product/brands/{id}`,

    //////////////////////Product Categories/////////////////////////
    ADD_PRODUCT_CATEGORY: `product/category/add`,
    SEARCH_PRODUCT_CATEGORIES: `product/categories/search`,
    UPDATE_PRODUCT_CATEGORY: `product/categories/{id}/update`,
    GET_PRODUCT_CATEGORIES: `product/categories`,
    GET_PRODUCT_CATEGORY_BY_ID: `product/categories/{id}`,

    //////////////////////Product Brands/////////////////////////
    ADD_PRODUCT_TYPE: `product/type/add`,
    SEARCH_PRODUCT_TYPES: `product/types/search`,
    UPDATE_PRODUCT_TYPE: `product/types/{id}/update`,
    GET_PRODUCT_TYPES: `product/types`,
    GET_PRODUCT_TYPE_BY_ID: `product/types/{id}`,

    GET_BANNERS: `banners`,

}