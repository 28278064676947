<div class="single-product-box">
    <div class="product-image">
        <a [routerLink]="'/tractors/' + data.abbreviation" class="d-block">
            <img src="assets/images/loading.gif"
                [attr.data-src]="_fileUploadService.getFileUrl(data.images[0].originalUrl,'media')" [alt]="data.title"
                (error)="_fileUploadService.onImgError($event, 'media')" appLazyLoad />
        </a>
    </div>
    <div class="product-content p-3">
        <h3><a [routerLink]="'/tractors/' + data.abbreviation">{{data.name |
                titlecase}}</a></h3>

        <div class="price">
            <span class="new">{{data.newprice}}</span>
            <!-- <span class="old mx-2">{{data.oldprice}}</span> -->
        </div>
        <div class="power mt-2 d-flex align-item-center gap-2 justify-content-center">
            <p><span>(&)</span> {{data.hpCategory}} HP</p>
            <p><span>(&)</span> {{data.engineRatedRpm}} CC</p>
        </div>
    </div>
    <div class="view_button w-100">
        <button type="button" class="w-100 p-2" (click)="sendRequest(data)">Check Tractor
            Price</button>
    </div>
</div>