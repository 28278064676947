import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {
    transform(value: number | string, currencyCode: string = 'INR'): string {
        // Ensure the value is a number
        const numericValue: number = typeof value === 'string' ? parseFloat(value) : value;

        // Format the number as currency
        const formattedValue: string = numericValue.toLocaleString('en-IN', {
            style: 'currency',
            currency: currencyCode
        });

        return formattedValue;
    }
}
