<div class="modal-header d-flex justify-content-between">
    <h4 class="modal-title" id="modal-basic-title">{{data.name}}</h4>
    <button type="button" class="close btn btn-rounded m-1" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true" style="font-size: 35px; color: black;">&times;</span>
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()" class="needs-validation" novalidate>

        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="name">Name</label>
                    <input id="name" class="form-control" formControlName="name" type="text">
                    <div *ngIf="formGroup.get('name').invalid && (formGroup.get('name').dirty || formGroup.get('name').touched)"
                        class="alert alert-danger mt-2">
                        <div *ngIf="formGroup.get('name').errors.required">
                            Name is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="mobileNumber">Mobile Number</label>
                    <input id="mobileNumber" class="form-control" formControlName="mobileNumber" type="tel">
                    <div *ngIf="formGroup.get('mobileNumber').invalid && (formGroup.get('mobileNumber').dirty || formGroup.get('mobileNumber').touched)"
                        class="alert alert-danger mt-2">
                        <div *ngIf="formGroup.get('mobileNumber').errors.required">
                            Mobile number is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="form-group">
                    <label for="state">State</label>
                    <input id="state" class="form-control" formControlName="state" type="text">
                    <div *ngIf="formGroup.get('state').invalid && (formGroup.get('state').dirty || formGroup.get('state').touched)"
                        class="alert alert-danger mt-2">
                        <div *ngIf="formGroup.get('state').errors.required">
                            State is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="district">District</label>
                    <input id="district" class="form-control" formControlName="district" type="text">
                    <div *ngIf="formGroup.get('district').invalid && (formGroup.get('district').dirty || formGroup.get('district').touched)"
                        class="alert alert-danger mt-2">
                        <div *ngIf="formGroup.get('district').errors.required">
                            District is required.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label for="tehsil">Tehsil</label>
                    <input id="tehsil" class="form-control" formControlName="tehsil" type="text">
                    <div *ngIf="formGroup.get('tehsil').invalid && (formGroup.get('tehsil').dirty || formGroup.get('tehsil').touched)"
                        class="alert alert-danger mt-2">
                        <div *ngIf="formGroup.get('tehsil').errors.required">
                            Tehsil is required.
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div class="form-group form-check">
            <input id="loanOffer" class="form-check-input" #checkOffers type="checkbox">
            <label class="form-check-label" for="loanOffer">Also, looking for loan offers?</label>
        </div>
        <br>
        <div class="text-center mt-1">
            <button type="submit" class="btn btn-primary btn-block" [disabled]="formGroup.invalid">Get Best Price</button>
        </div>

        <div class="text-center mt-3">
            <p>By clicking on Submit, you agree to our <a href="#">terms and conditions</a>.</p>
        </div>
    </form>
</div>