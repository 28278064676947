import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpEventType, HttpEvent } from '@angular/common/http';
import { FileUploadService } from '../../services/file-upload.service';
import { UtilityService } from '../../services/utility.service';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-upload-images',
  templateUrl: './upload-images.component.html',
  styleUrls: ['./upload-images.component.scss']
})
export class UploadImagesComponent implements OnInit {

  @Input("files") files: any[] = [];
  @Input("type") type: any;
  @Output() outputFile = new EventEmitter();

  isLoading: boolean = false;
  progressBarForFile: number = 0;

  constructor(
    public _fileUploadService: FileUploadService,
    public _utilityService: UtilityService,
    private _loadingService: LoadingService
  ) { }

  ngOnInit() {
  }

  /**
 * On select file
 * 
 * @param event 
 */
  // onSelectFile(event: any) {
  //   this._loadingService.loading.next(true);
  //   if (event.target.files && event.target.files[0]) {
  //     this.uploadFile(event.target.files[0]);
  //   }
  //   this._loadingService.loading.next(false);
  // }

  /**
 * On select file
 * 
 * @param event 
 */
  onSelectFile(event: any) {
    if (this.files.length < 3) {
      this._loadingService.loading.next(true);

      const MAX_FILE_SIZE = 1 * 1024 * 1024; // 1 MB
      const MAX_WIDTH = 1920; // Maximum width in pixels
      const MAX_HEIGHT = 1080; // Maximum height in pixels

      if (event.target.files && event.target.files[0]) {
        const file = event.target.files[0];

        // Validate file size
        if (file.size > MAX_FILE_SIZE) {
          alert('File size exceeds the maximum limit of 1 MB.');
          this._loadingService.loading.next(false);
          return;
        }

        // Validate image dimensions
        const img = new Image();
        img.onload = () => {
          if (img.width > MAX_WIDTH || img.height > MAX_HEIGHT) {
            alert(`Image dimensions exceed the maximum limit of ${MAX_WIDTH}x${MAX_HEIGHT} pixels.`);
            this._loadingService.loading.next(false);
          } else {
            // Proceed with the file upload if validations pass
            this.uploadFile(file);
            this._loadingService.loading.next(false);
          }
        };
        img.onerror = () => {
          alert('Invalid image file.');
          this._loadingService.loading.next(false);
        };
        img.src = URL.createObjectURL(file);
      } else {
        this._loadingService.loading.next(false);
      }
    } else {
      alert(`Max images is 3`);
    }
  }

  /**
   * Load image
   */
  loadImage() {
    this._loadingService.loading.next(false);
  }

  /**
   * Remove file
   */
  removeFile(file: any, index: any) {
    this._loadingService.loading.next(true);
    this._fileUploadService.delete(file).then((response: any) => {
      if (response && response.status === 'OK') {
        this.files.splice(index, 1);
        this._loadingService.loading.next(false);
      } else {
        this._loadingService.loading.next(false);
      }
    }, error => {
      console.log(error);
      this._loadingService.loading.next(false);
    })
  }

  /**
    * Upload File
    * 
    * @param multiPartFile 
    */
  uploadFile(multiPartFile: any) {
    this.isLoading = true;
    let formData = new FormData();
    formData.append('file', multiPartFile);
    formData.append('directory', this.type);
    formData.append('fileName', multiPartFile.name);
    this._fileUploadService.uploadImage(formData).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          console.log('Request has been made!');
          break;
        case HttpEventType.ResponseHeader:
          console.log('Response header has been received!');
          break;
        case HttpEventType.UploadProgress:
          if (event.total) {
            this.progressBarForFile = Math.round((event.loaded / event.total) * 100);
          }
          break;
        case HttpEventType.Response:
          console.log('User successfully created!', event.body);
          this.files.push(event.body.data);
          setTimeout(() => {
            this.progressBarForFile = 0;
            this.isLoading = false;
            this.outputFile.emit({ files: this.files, isSubmitButtonDisable: false });
          }, 500);
      }
    });
  }

}
