import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { AboutComponent } from './components/pages/about/about.component';
import { SaasHomeComponent } from './components/pages/saas-home/saas-home.component';
import { NewTractorsComponent } from './components/pages/tractors/new-tractors/new-tractors.component';
import { MediaDetailsComponent } from './components/pages/media/media-details/media-details.component';
import { MediaComponent } from './components/pages/media/media.component';
import { MediaService } from './components/pages/media/media.service';
import { BuyUsedProductsComponent } from './components/pages/products/buy-used-products/buy-used-products.component';
import { NewproductDetailsComponent } from './components/pages/products/new-products/new-product-details/new-product-details.component';
import { NewProductsComponent } from './components/pages/products/new-products/new-products.component';
import { ProductDetailsComponent } from './components/pages/products/product-details/product-details.component';
import { ProductService } from './components/pages/products/product.service';
import { SellUsedProductsComponent } from './components/pages/products/sell-used-products/sell-used-products.component';
import { BuyUsedTractorDetailsComponent } from './components/pages/tractors/buy-used-tractors/buy-used-tractor-details/buy-used-tractor-details.component';
import { BuyUsedTractorsComponent } from './components/pages/tractors/buy-used-tractors/buy-used-tractors.component';
import { NewTractorDetailsComponent } from './components/pages/tractors/new-tractors/new-tractor-details/new-tractor-details.component';
import { SellUsedTractorsComponent } from './components/pages/tractors/sell-used-tractors/sell-used-tractors.component';
import { TractorService } from './components/pages/tractors/tractor.service';
import { UsedProductDetailsComponent } from './components/pages/products/buy-used-products/used-product-details/used-product-details.component';

const routes: Routes = [
    { path: '', component: SaasHomeComponent },
    { path: 'about', component: AboutComponent },
    { path: 'contact', component: ContactComponent },
    { path: 'tractors', component: NewTractorsComponent },
    { path: 'buy-used-tractors', component: BuyUsedTractorsComponent },
    { path: 'sell-used-tractors', component: SellUsedTractorsComponent },
    { path: 'tractors/:abbreviation', component: NewTractorDetailsComponent, resolve: { data: TractorService } },
    { path: 'implements', component: NewProductsComponent },
    { path: 'implements/:abbreviation', component: NewproductDetailsComponent, resolve: { data: ProductService } },
    { path: 'buy-used-implements', component: BuyUsedProductsComponent },
    { path: 'used-implements/:abbreviation', component: UsedProductDetailsComponent, resolve: { data: ProductService } },
    { path: 'used-tractors/:abbreviation', component: BuyUsedTractorDetailsComponent, resolve: { data: TractorService } },
    { path: 'sell-used-implements', component: SellUsedProductsComponent },
    { path: 'implements/:abberviation', component: ProductDetailsComponent },
    { path: 'news-and-updates/:category', component: MediaComponent },
    { path: 'news-and-updates/:category/:abbreviation', component: MediaDetailsComponent, resolve: { data: MediaService } },
    { path: 'error', component: ErrorComponent },
    { path: 'coming-soon', component: ComingSoonComponent },
    { path: '**', component: ErrorComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }