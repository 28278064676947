import { Component, OnInit } from '@angular/core';
import { TractorService } from '../tractor.service';
import { FormGroup } from '@angular/forms';
import { ConstantService } from 'src/app/shared/services/constant.service';
import { SellUsedTractorsStepCompletedComponent } from './sell-used-tractors-step-completed/sell-used-tractors-step-completed.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegionSellUsedTractorsComponent } from './region-sell-used-tractors/region-sell-used-tractors.component';
import { ApiResponse } from 'src/app/shared/interfaces/api-response.interface';

@Component({
  selector: 'app-sell-used-tractors',
  templateUrl: './sell-used-tractors.component.html',
  styleUrls: ['./sell-used-tractors.component.scss']
})
export class SellUsedTractorsComponent implements OnInit {
  formGroup!: FormGroup;
  steps: string[] = ['Step 1', 'Tractor Type', 'Condition State', 'Images'];
  currentStep: number = 0;
  brands: any[] = [];
  images: any[] = [];
  models: any[] = [
    { id: 1, name: "Model 1" },
    { id: 2, name: "Model 2" }
  ];

  constructor(
    private _dataService: TractorService,
    public _constantService: ConstantService,
    private modalService: NgbModal
  ) {
    this.formGroup = _dataService.createSellUsedTractorForm();
    _dataService.brands.subscribe((response: any) => {
      if (response) {
        this.brands = response;
      }
    })
  }

  ngOnInit(): void {
  }

  nextStep() {
    if (this.currentStep < this.steps.length - 1) {
      this.currentStep++;
    }
  }

  previousStep() {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }

  goToStep(stepIndex: number) {
    if (this.isStepAccessible(stepIndex)) {
      this.currentStep = stepIndex;
    }
  }

  isStepAccessible(stepIndex: number): boolean {
    for (let i = 0; i < stepIndex; i++) {
      if (this.formGroup.get(`step${i + 1}`).invalid) {
        return false;
      }
    }
    return true;
  }

  onSubmit() {
    if (this.formGroup.valid) {
      this.stepsCompleted(this.formGroup.value);
    }
  }

  stepsCompleted(data: any) {
    const modalRef = this.modalService.open(SellUsedTractorsStepCompletedComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg'
    });
    modalRef.componentInstance.data = data;

    modalRef.result.then((result) => {
      if (result) {
        this.regionPopup();
      }
    }, (reason) => {
      console.log('Err!', reason);
    });
  }

  regionPopup() {
    const modalRef = this.modalService.open(RegionSellUsedTractorsComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg'
    });
    modalRef.componentInstance.data = this.formGroup;

    modalRef.result.then((result) => {
      if (result) {
        this.formGroup.get(`step4`).get('sellingRegion').setValue(result);
        this.finalSubmit();
      }
    }, (reason) => {
      console.log('Err!', reason);
    });
  }

  finalSubmit() {
    const data = this.formGroup.getRawValue();
    let json = {};
    Object.keys(data).forEach(step => {
      Object.keys(data[step]).forEach(key => {
        json[key] = data[step][key];
      });
    });
    this._dataService.sellUsedTractor(json).then((response: ApiResponse) => {
      if (response.status === 'OK') {
        this.formGroup.reset();
        this.images = [];
        this.currentStep = 0;
      }
    })
  }

  getFiles(event: any) {
    this.images = event.files;
    this.formGroup.get(`step4`).get('images').setValue(event.files);
  }

}
