import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiResponse } from 'src/app/shared/interfaces/api-response.interface';
import { Pagination } from 'src/app/shared/interfaces/pagination.interface';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { TractorService } from '../tractor.service';
import { BuyUsedTractorsRequestComponent } from './buy-used-tractors-request/buy-used-tractors-request.component';
import { ConstantService } from 'src/app/shared/services/constant.service';

@Component({
  selector: 'app-buy-used-tractors',
  templateUrl: './buy-used-tractors.component.html',
  styleUrls: ['./buy-used-tractors.component.scss']
})
export class BuyUsedTractorsComponent implements OnInit {

  filter: any = {
    brand: null,
    price: null,
    hp: null
  }
  pagination: Pagination;
  sortBy: string = "created_at";
  orderBy: string = "desc";
  brands: any[] = [];
  years: number[] = [];

  constructor(
    public _dataService: TractorService,
    private _utilityService: UtilityService,
    private _loadingService: LoadingService,
    public _fileUploadService: FileUploadService,
    private _activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    public _constantService: ConstantService
  ) {
    this.years = _constantService.years;
    _dataService.brands.subscribe((response: any) => {
      if (response) {
        this.brands = response;
      }
    })
    this._activatedRoute.params.subscribe(params => {
      this.pagination = _utilityService.pagination;
      this.filter.brand = params['brand'];
      this.getDataList();
    });
  }

  ngOnInit(): void {
  }

  /**
    * Get next page data
    * 
    * @param page 
    */
  getPagination(event: any) {
    this.pagination = event;
    this.getDataList();
  }

  /**
   * Get Data List with pagination
   */
  async getDataList() {
    try {
      this._loadingService.loading.next(true);
      const response: ApiResponse = await this._dataService.searchUsedTractors(
        this.filter,
        this.pagination.perPage,
        this.pagination.currentPage,
        this.sortBy,
        this.orderBy
      );

      this.pagination = response.data;
    } catch (error) {
      console.error(error);
    } finally {
      this._loadingService.loading.next(false);
    }
  }

  sendRequest(data: any) {
    const modalRef = this.modalService.open(BuyUsedTractorsRequestComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg' // Set the size to large (lg)
    });
    modalRef.componentInstance.data = data;

    modalRef.result.then((result) => {
      if (result) {
        this.getDataList();
      }
    }, (reason) => {
      console.log('Err!', reason);
    });
  }

}
