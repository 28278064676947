import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiResponse } from 'src/app/shared/interfaces/api-response.interface';
import { Pagination } from 'src/app/shared/interfaces/pagination.interface';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { ProductService } from '../product.service';

@Component({
  selector: 'app-new-products',
  templateUrl: './new-products.component.html',
  styleUrls: ['./new-products.component.scss']
})
export class NewProductsComponent implements OnInit {

  filter: any = {
    brand: null,
    price: null,
    hp: null
  }
  pagination: Pagination;
  sortBy: string = "created_at";
  orderBy: string = "desc";
  brands: any[] = [];
  categories: any[] = [];
  types: any[] = [];

  constructor(
    public _dataService: ProductService,
    private _utilityService: UtilityService,
    private _loadingService: LoadingService,
    public _fileUploadService: FileUploadService,
    private _activatedRoute: ActivatedRoute
  ) {
    _dataService.brands.subscribe((response: any) => {
      if (response) {
        this.brands = response;
      }
    })

    _dataService.categories.subscribe((response: any) => {
      if (response) {
        this.categories = response;
      }
    })

    _dataService.types.subscribe((response: any) => {
      if (response) {
        this.types = response;
      }
    })

    this._activatedRoute.params.subscribe(params => {
      this.pagination = _utilityService.pagination;
      this.filter.brand = params['brand'];
      this.getDataList();
    });
  }

  ngOnInit(): void {
  }

  /**
    * Get next page data
    * 
    * @param page 
    */
  getPagination(event: any) {
    this.pagination = event;
    this.getDataList();
  }

  /**
   * Get Data List with pagination
   */
  async getDataList() {
    try {
      this._loadingService.loading.next(true);
      const response: ApiResponse = await this._dataService.searchNewProducts(
        this.filter,
        this.pagination.perPage,
        this.pagination.currentPage,
        this.sortBy,
        this.orderBy
      );

      this.pagination = response.data;
    } catch (error) {
      console.error(error);
    } finally {
      this._loadingService.loading.next(false);
    }
  }

}
