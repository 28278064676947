import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import { MediaService } from './components/pages/media/media.service';
import { ProductService } from './components/pages/products/product.service';
import { TractorService } from './components/pages/tractors/tractor.service';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;

    constructor(
        private router: Router,
        private _mediaService: MediaService,
        private _tractorService: TractorService,
        private _productService: ProductService
    ) {
        _mediaService.getCategories().then((response: any) => {
            _mediaService.categories.next(response.data);
        })

        _tractorService.getBrands().then((response: any) => {
            _tractorService.brands.next(response.data);
        })

        _productService.getBrands().then((response: any) => {
            _productService.brands.next(response.data);
        })

        _productService.getCategories().then((response: any) => {
            _productService.categories.next(response.data);
        })

        _productService.getTypes().then((response: any) => {
            _productService.types.next(response.data);
        })

        _productService.getBanners().then((response: any) => {
            _productService.banners.next(response.data);
        })
    }

    ngOnInit() {
        this.recallJsFuntions();
    }

    recallJsFuntions() {
        this.router.events
            .subscribe((event) => {
                if (event instanceof NavigationStart) {
                    $('.preloader').fadeIn('slow');
                }
            });
        this.routerSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
            .subscribe(event => {
                //$.getScript('../assets/js/main.js');
                $('.preloader-area').fadeOut('slow');
                this.location = this.router.url;
                if (!(event instanceof NavigationEnd)) {
                    return;
                }
                window.scrollTo(0, 0);
            });
    }
}