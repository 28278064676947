import { Injectable } from '@angular/core';
import { LoadingService } from './loading.service';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(
        private _loadingService: LoadingService
    ) { }

    _handleError(errorMessage?: any) {
        this._loadingService.loading.next(false);
        this.error(errorMessage || 'An error occurred');
    }

    success(message: any) {

    }

    error(message: any) {

    }
}
