import { Component, Input, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from '../../product.service';

@Component({
  selector: 'app-used-product-request',
  templateUrl: './used-product-request.component.html',
  styleUrls: ['./used-product-request.component.scss']
})
export class UsedProductRequestComponent {

  modal = inject(NgbActiveModal);

  @Input() data: any;

  formGroup!: FormGroup;

  constructor(
    private _dataService: ProductService
  ) {
    this.formGroup = this._dataService.createFormForUsedProductRequest();
  }

  onSubmit() {
    const data = this.formGroup.getRawValue();
    this._dataService.sendUsedProductRequest(data, this.data.id, this.modal);
  }
}
