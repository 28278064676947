import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { TractorService } from '../../tractor.service';
import { ConstantService } from 'src/app/shared/services/constant.service';
import { BuyUsedTractorsRequestComponent } from '../buy-used-tractors-request/buy-used-tractors-request.component';

@Component({
  selector: 'app-buy-used-tractor-details',
  templateUrl: './buy-used-tractor-details.component.html',
  styleUrls: ['./buy-used-tractor-details.component.scss']
})
export class BuyUsedTractorDetailsComponent implements OnInit {
  dataElement: any;

  constructor(
    private modalService: NgbModal,
    public _dataService: TractorService,
    public _fileUploadService: FileUploadService,
    public _constantService: ConstantService
  ) { }

  ngOnInit(): void {
    this.dataElement = this._dataService.data;
  }

  sendRequest(data: any) {
    const modalRef = this.modalService.open(BuyUsedTractorsRequestComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg'
    });
    modalRef.componentInstance.data = data;

    modalRef.result.then((result) => {
      if (result) {

      }
    }, (reason) => {
      console.log('Err!', reason);
    });
  }

}
