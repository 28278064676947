

<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>About Us</h2>

                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="about-section ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="circulate-image">
                    <img src="assets/images/tractor/5050-d-1632220934.webp" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content-area">
                    <span>About us</span>
                    <h3>Circulate every stage of the strategic business </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed aliquaem ipsum dolor si Ut enim ad
                        minim venia.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim venia.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="what-we-do-section pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="we-do-content-area">
                    <span>What We do</span>
                    <h3>We develop digital strategies products and services</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed aliquaem ipsum dolor si Ut enim ad
                        minim venia.</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim venia.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="we-do-image">
                    <img src="assets/images/tractor/265-di-1632210883.webp" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="saying-section ptb-100">
    <div class="container">
        <div class="saying-title">
            <h3>What is saying clients about us</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolet, consectetur adipiscing seore suspendisse ultrices gravir.</p>
        </div>

        <div class="saying-slides owl-carousel owl-theme">
            <div class="saying-single-item">
                <div class="saying-content-text">
                    <h3>It’s Amazing Agency i made ever</h3>
                    <p>Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem
                        ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>
                <div class="saying-info">
                    <div class="say-image">
                        <img src="assets/images/tractor/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg" alt="image">
                    </div>
                    <h4>Ketax Andon</h4>
                    <span>Bussiness Solution</span>
                </div>
            </div>

            <div class="saying-single-item">
                <div class="saying-content-text">
                    <h3>It’s Amazing Agency i made ever</h3>
                    <p>Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem
                        ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>
                <div class="saying-info">
                    <div class="say-image">
                        <img src="assets/images/tractor/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg" alt="image">
                    </div>
                    <h4>Kolil Ward</h4>
                    <span>CEO Manager</span>
                </div>
            </div>

            <div class="saying-single-item">
                <div class="saying-content-text">
                    <h3>It’s Amazing Agency i made ever</h3>
                    <p>Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem
                        ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>
                <div class="saying-info">
                    <div class="say-image">
                        <img src="assets/images/tractor/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg" alt="image">
                    </div>
                    <h4>Jandhu Dew</h4>
                    <span>Marketing Manager</span>
                </div>
            </div>

            <div class="saying-single-item">
                <div class="saying-content-text">
                    <h3>It’s Amazing Agency i made ever</h3>
                    <p>Lorem ipsum dolor sit ametipisicing elit, sed do eiusmod tempor incididunt ut labore et Lorem
                        ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                        et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                </div>
                <div class="saying-info">
                    <div class="say-image">
                        <img src="assets/images/tractor/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg" alt="image">
                    </div>
                    <h4>Andon Smait</h4>
                    <span>Bussiness Solution</span>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="team-section inner-team ptb-100">
    <div class="container">
        <div class="team-title">
            <span>Our Team Members</span>
            <h3>Executive Team Support</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim venia.</p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/images/tractor/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg" alt="image">
                    </div>

                    <div class="member-content">
                        <h3><a routerLink="/">White Amlington</a></h3>
                        <span>Manager Logistic</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/images/tractor/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg" alt="image">
                    </div>

                    <div class="member-content">
                        <h3><a routerLink="/">Agaton Ronald</a></h3>
                        <span>Scientist Ecology</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/images/tractor/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg" alt="image">
                    </div>

                    <div class="member-content">
                        <h3><a routerLink="/">Alex Wood</a></h3>
                        <span>Scientist Economic</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-team-member">
                    <div class="member-image">
                        <img src="assets/images/tractor/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg" alt="image">
                    </div>

                    <div class="member-content">
                        <h3><a routerLink="/">Mexwell White</a></h3>
                        <span>Owner & CEO of Economic</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="fun-facts-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="100">00</span>
                        <span class="sign-icon">%</span>
                    </h3>
                    <p>Completed projects</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="253">00</span>
                        <span class="sign-icon">+</span>
                    </h3>
                    <p>Customer satisfaction</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="24">00</span>
                        <span class="sign-icon">h</span>
                    </h3>
                    <p>Expert Support Team</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-fun-fact">
                    <h3>
                        <span class="odometer" data-count="78">00</span>
                        <span class="sign-icon">k</span>
                    </h3>
                    <p>Sales in Count</p>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="partner-section ptb-100">
    <div class="container">
        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="../../../../assets/images/tractor/5050-d-1632220934.webp" alt="partner">
                </a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="../../../../assets/images/tractor/5050-d-1632220934.webp" alt="partner">
                </a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="../../../../assets/images/tractor/5050-d-1632220934.webp" alt="partner">
                </a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="../../../../assets/images/tractor/5050-d-1632220934.webp" alt="partner">
                </a>
            </div>

            <div class="partner-item">
                <a href="#" target="_blank" class="d-block">
                    <img src="../../../../assets/images/tractor/5050-d-1632220934.webp" alt="partner">
                </a>
            </div>
        </div>
    </div>
</div>

<section class="update-section inner-update-section ptb-100">
    <div class="container">
        <div class="update-title">
            <h3>Get Every Single Update</h3>
        </div>
        <form class="update-form">
            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
            <button type="submit">Subscribe Newsletter</button>
        </form>
    </div>

    <div class="shape"><img src="assets/images/shape/shape19.png" alt="Images"></div>
    <div class="shape-2"><img src="assets/images/shape/shape20.png" alt="Images"></div>
</section>