import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TractorService } from '../../tractor.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-region-sell-used-tractors',
  templateUrl: './region-sell-used-tractors.component.html',
  styleUrls: ['./region-sell-used-tractors.component.scss']
})
export class RegionSellUsedTractorsComponent {

  modal = inject(NgbActiveModal);

  @Input() data: any;
  formGroup: FormGroup;

  constructor(
    private fb: FormBuilder
  ) {
    this.formGroup = this.fb.group({
      reason: ['']
    });
  }

  onSubmit() {
    const data = this.formGroup.getRawValue();
    this.modal.close(data.reason);
  }
}
