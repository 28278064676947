import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Pagination } from '../../interfaces/pagination.interface';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html'
})
export class PaginationComponent {

  @Input("pagination") pagination!: Pagination;
  @Output() outputFile = new EventEmitter();

  /**
   * On Page change
   * 
   * @param pageNumber 
   */
  onPageChange(pageNumber: number) {
    this.pagination.currentPage = pageNumber;
    this.outputFile.emit(this.pagination);
  }

  /**
   * Get page numbers
   * 
   * @param totalPages 
   * @returns 
   */
  getPages(totalPages: number): number[] {
    const currentPage = this.pagination.currentPage;
    const pagesToShow = 3;
    const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);
    return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
  }

}
