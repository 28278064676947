import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

  constructor() { }

  listdata = [
    {
      tractorname:'Mahindra Tractor',
      cylinder:'4',
      hp:'55 HP',
      ptohp:'50 HP',
      gearbox:'15 Forward + 15 Reverse',
      breaks:'Mechanical, oil immersed multi disc Brakes',
      warranty:'2000 hour Or 2 Year',
      price:'From: 9.95-10.65 Lac*'
    }
  ]

  ngOnInit(): void {
  }

}
