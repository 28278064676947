<div class="modal-body text-center position-relative">
    <!-- Close button positioned in the top right corner -->
    <button type="button" class="close btn btn-rounded position-absolute" aria-label="Close"
        style="top: 10px; right: 10px;" (click)="modal.dismiss()">
        <span aria-hidden="true" style="font-size: 35px; color: black;">&times;</span>
    </button>
    <div class="mb-3">
        <img src="assets/images/righticon.webp" alt="Check Mark" class="img-fluid">
    </div>
    <h4 class="modal-title mb-3" id="thankYouModalLabel">Thank You for contacting Us!</h4>
    <p>Thank you for contacting Tractor Junction! You can buy old tractors by manually contacting the seller. Seller
        details provided below have been received.</p>
    <hr>
    <p class="font-weight-bold">You can also apply for a loan against your tractor</p>
    <div>
        <button type="button" class="btn btn-primary" (click)="modal.close('yes')">Yes, I’m interested</button>
        &nbsp;
        <button type="button" class="btn btn-secondary" (click)="modal.close('no')">No, I want to sell</button>
    </div>
</div>