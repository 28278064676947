<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Sell Used Tractors</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Sell Used Tractors</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="finance_container px-5 mt-5">
    <div class="row">
        <div class="form_section col-md-5 bg-light shadow my-4 p-4">
            <div class="container">
                <div class="card">
                    <!-- <div class="card-header" *ngIf="currentStep > 0">
                        <ul class="nav nav-pills nav-justified">
                            <ng-container *ngFor="let step of steps; let i = index">
                                <li class="nav-item" *ngIf="i > 0">
                                    <a class="nav-link" [class.active]="currentStep === i" (click)="goToStep(i)"
                                        [class.disabled]="!isStepAccessible(i)">
                                        {{ step }}
                                    </a>
                                </li>
                            </ng-container>
                        </ul>
                    </div> -->

                    <div class="card-header" *ngIf="currentStep > 0">
                        <ul class="nav nav-pills nav-justified">
                            <ng-container *ngFor="let step of steps; let i = index">
                                <li class="nav-item" *ngIf="i > 0">
                                    <a class="nav-link" [class.active]="currentStep === i">{{ step }}</a>
                                </li>
                            </ng-container>
                        </ul>
                    </div>

                    <div class="card-body">
                        <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
                            <div [ngSwitch]="currentStep">
                                <div *ngSwitchCase="0" formGroupName="step1">
                                    <div>
                                        <h3>Sell Your Used Tractor</h3>
                                        <div class="form-group mt-4">
                                            <label for="location">Location</label>
                                            <input id="location" type="text" class="form-control"
                                                formControlName="location" placeholder="Enter location">
                                            <div *ngIf="formGroup.get('step1.location').invalid && formGroup.get('step1.location').touched"
                                                class="text-danger">
                                                Location is required.
                                            </div>
                                        </div>
                                        <div class="form-group mt-4">
                                            <label for="name">Name</label>
                                            <input id="name" type="text" class="form-control" formControlName="name"
                                                placeholder="Enter full name">
                                            <div *ngIf="formGroup.get('step1.name').invalid && formGroup.get('step1.name').touched"
                                                class="text-danger">
                                                Name is required.
                                            </div>
                                        </div>
                                        <div class="form-group mt-4">
                                            <label for="mobileNumber">Mobile Number</label>
                                            <input id="mobileNumber" type="text" class="form-control"
                                                formControlName="mobileNumber" placeholder="Enter mobile number">
                                            <div *ngIf="formGroup.get('step1.mobileNumber').invalid && formGroup.get('step1.mobileNumber').touched"
                                                class="text-danger">
                                                Valid Mobile Number is required.
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div *ngSwitchCase="1" formGroupName="step2">
                                    <div>
                                        <h3>Which tractor do you Own?</h3>
                                        <div class="form-group mt-4">
                                            <label for="brand">Brand</label>
                                            <select id="brand" class="form-control" formControlName="brandId">
                                                <option value="" disabled>Select a brand</option>
                                                <option *ngFor="let brand of brands" [value]="brand.id">{{ brand.name }}
                                                </option>
                                            </select>
                                            <div *ngIf="formGroup.get('step2.brandId').invalid && formGroup.get('step2.brandId').touched"
                                                class="text-danger">
                                                Brand is required.
                                            </div>
                                        </div>
                                        <div class="form-group mt-4">
                                            <label for="model">Model</label>
                                            <!-- <select id="model" class="form-control" formControlName="model">
                                                <option value="" disabled>Select a model</option>
                                                <option *ngFor="let brand of models" [value]="brand.id">{{ brand.name }}
                                                </option>
                                            </select> -->
                                            <input id="model" type="text" class="form-control" formControlName="model"
                                                placeholder="Enter model">
                                            <div *ngIf="formGroup.get('step2.model').invalid && formGroup.get('step2.model').touched"
                                                class="text-danger">
                                                Model is required.
                                            </div>
                                        </div>
                                        <div class="form-group mt-4">
                                            <label for="year">Year</label>
                                            <select id="year" class="form-control" formControlName="year">
                                                <option value="" disabled>Select a year</option>
                                                <option *ngFor="let item of _constantService.years" [value]="item">{{
                                                    item }}
                                                </option>
                                            </select>
                                            <div *ngIf="formGroup.get('step2.year').invalid && formGroup.get('step2.year').touched"
                                                class="text-danger">
                                                Year is required.
                                            </div>
                                        </div>
                                        <div class="form-group mt-4">
                                            <label for="price">Price</label>
                                            <input id="price" type="number" class="form-control" formControlName="price"
                                                placeholder="Enter price">
                                            <div *ngIf="formGroup.get('step2.price').invalid && formGroup.get('step2.price').touched"
                                                class="text-danger">
                                                Price is required.
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div *ngSwitchCase="2" formGroupName="step3">
                                    <div>
                                        <h3>Share Tractor Conditions With Buyers</h3>
                                        <div class="form-group mt-4">
                                            <label for="engineCondition">Engine Condition</label>
                                            <select id="engineCondition" class="form-control"
                                                formControlName="engineCondition">
                                                <option value="" disabled>Select a Engine Condition</option>
                                                <option *ngFor="let item of _constantService.ENGINE_CONDITIONS"
                                                    [value]="item.value">{{
                                                    item.key }}
                                                </option>
                                            </select>
                                            <div *ngIf="formGroup.get('step3.engineCondition').invalid && formGroup.get('step3.engineCondition').touched"
                                                class="text-danger">
                                                Engine Condition is required.
                                            </div>
                                        </div>
                                        <div class="form-group mt-4">
                                            <label for="tireCondition">Tire Condition</label>
                                            <select id="tireCondition" class="form-control"
                                                formControlName="tireCondition">
                                                <option value="" disabled>Select a Tire Condition</option>
                                                <option *ngFor="let item of _constantService.TIRE_CONDITIONS"
                                                    [value]="item.value">{{
                                                    item.key }}
                                                </option>
                                            </select>
                                            <div *ngIf="formGroup.get('step3.tireCondition').invalid && formGroup.get('step3.tireCondition').touched"
                                                class="text-danger">
                                                Tire Condition is required.
                                            </div>
                                        </div>
                                        <div class="form-group mt-4">
                                            <label for="hoursDriven">Hours Driven</label>
                                            <select id="hoursDriven" class="form-control" formControlName="hoursDriven">
                                                <option value="" disabled>Select a Hours Driven</option>
                                                <option *ngFor="let item of _constantService.HOURS_DRIVEN"
                                                    [value]="item.value">{{
                                                    item.key }}
                                                </option>
                                            </select>
                                            <div *ngIf="formGroup.get('step3.hoursDriven').invalid && formGroup.get('step3.hoursDriven').touched"
                                                class="text-danger">
                                                Hours Driven is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngSwitchCase="3" formGroupName="step4">
                                    <div>
                                        <h3>Upload Tractor Images</h3>
                                        <label for="images">Images</label>
                                        <br>
                                        <app-upload-images [files]="images" [type]="'tractors'"
                                            (outputFile)="getFiles($event)"></app-upload-images>
                                        <div class="form-group mt-4">
                                            <label for="daysForSell">How early do you want to sell?</label>
                                            <select id="daysForSell" class="form-control" formControlName="daysForSell">
                                                <option value="" disabled>Select a days for ell</option>
                                                <option *ngFor="let item of _constantService.DAYS_FOR_SELL"
                                                    [value]="item.value">{{
                                                    item.key }}
                                                </option>
                                            </select>
                                            <div *ngIf="formGroup.get('step4.daysForSell').invalid && formGroup.get('step4.daysForSell').touched"
                                                class="text-danger">
                                                Days For Sell are required.
                                            </div>
                                        </div>
                                        <!-- <div class="form-group mt-4">
                                            <label for="sellingRegion">Selling Region</label>
                                            <input id="sellingRegion" type="text" class="form-control"
                                                formControlName="sellingRegion">
                                            <div *ngIf="formGroup.get('step4.sellingRegion').invalid && formGroup.get('step4.sellingRegion').touched"
                                                class="text-danger">
                                                Selling Region is required.
                                            </div>
                                        </div> -->
                                    </div>

                                </div>
                            </div>
                            <div class="mt-3">
                                <button type="button" class="btn btn-secondary" (click)="previousStep()"
                                    *ngIf="currentStep > 0">Previous</button>
                                &nbsp;
                                <!-- <button type="button" class="btn btn-primary" (click)="nextStep()"
                                    *ngIf="currentStep < 3">Next</button> -->
                                <button type="button" class="btn btn-primary" (click)="nextStep()"
                                    *ngIf="currentStep === 0" [disabled]="formGroup.get('step1').invalid">Sell
                                    Now</button>
                                <button type="button" class="btn btn-primary" (click)="nextStep()"
                                    *ngIf="currentStep === 1" [disabled]="formGroup.get('step2').invalid">Next</button>
                                <button type="button" class="btn btn-primary" (click)="nextStep()"
                                    *ngIf="currentStep === 2" [disabled]="formGroup.get('step3').invalid">Next</button>
                                <button type="submit" class="btn btn-success" *ngIf="currentStep === 3"
                                    [disabled]="formGroup.get('step4').invalid">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>


        </div>
    </div>
</div>

<section class="update-section inner-update-section ptb-100">
    <div class="container">
        <div class="update-title">
            <h3>Get Every Single Update</h3>
        </div>

        <form class="update-form">
            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
            <button type="submit">Subscribe Newsletter</button>
        </form>
    </div>

    <div class="shape"><img src="assets/images/shape/shape19.png" alt="Images"></div>
    <div class="shape-2"><img src="assets/images/shape/shape20.png" alt="Images"></div>
</section>