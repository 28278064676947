import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class ConstantService {
  ACTIVE_STATUS: any[] = [
    { key: true, value: 'Active', color: 'primary' },
    { key: false, value: 'Inactive', color: 'warn' }
  ]

  PRICE: any[] = [
    { key: "0 Lakh - 3 Lakh", value: { from: 0, to: 3 } },
    { key: "3 Lakh - 5 Lakh", value: { from: 3, to: 5 } },
    { key: "5 Lakh - 7 Lakh", value: { from: 5, to: 7 } },
    { key: "7 Lakh - 10 Lakh", value: { from: 7, to: 10 } },
    { key: "Above 10 Lakh", value: { from: 10, to: 0 } }
  ];

  HP: any[] = [
    { key: "1 HP - 10 HP", value: { from: 1, to: 10 } },
    { key: "11 HP - 20 HP", value: { from: 11, to: 20 } },
    { key: "21 HP - 30 HP", value: { from: 21, to: 30 } },
    { key: "31 HP - 40 HP", value: { from: 31, to: 40 } },
    { key: "41 HP - 50 HP", value: { from: 41, to: 50 } },
    { key: "51 HP - 60 HP", value: { from: 51, to: 60 } },
    { key: "Above 75 HP", value: { from: 10, to: 0 } }
  ];

  ENGINE_CONDITIONS = [
    { key: "0-25% (Poor)", value: "1" },
    { key: "26-50% (Average)", value: "2" },
    { key: "51-75% (Good)", value: "3" },
    { key: "76-100% (Very Good)", value: "4" }
  ];

  TIRE_CONDITIONS = [
    { key: "0-25% (Poor)", value: "1" },
    { key: "26-50% (Average)", value: "2" },
    { key: "51-75% (Good)", value: "3" },
    { key: "76-100% (Very Good)", value: "4" }
  ];

  HOURS_DRIVEN = [
    { key: "Less than 1000", value: "1" },
    { key: "1001 - 2000", value: "2" },
    { key: "2001 - 3000", value: "3" },
    { key: "3001 - 4000", value: "4" },
    { key: "4001 - 5000", value: "5" },
    { key: "5001 - 6000", value: "6" },
    { key: "6001 - 7000", value: "7" },
    { key: "7001 - 8000", value: "8" },
    { key: "8001 - 9000", value: "9" },
    { key: "9001 - 10000", value: "10" },
    { key: "Above 10000", value: "11" },
    { key: "Not Available", value: "12" }
  ];

  DAYS_FOR_SELL = [
    { key: "Within 15 Days", value: "1" },
    { key: "15 - 30 Days", value: "2" },
    { key: "More than 30 Days", value: "3" }
  ];

  years: number[] = [];

  constructor() {
    this.generateYearList();
  }

  generateYearList() {
    const currentYear = new Date().getFullYear();
    for (let i = 0; i < 10; i++) {
      this.years.push(currentYear - i);
    }
  }

  getEngineCondition(value: any) {
    return this.ENGINE_CONDITIONS.find((item: any) => item.value === value).key;
  }

  getTireCondition(value: any) {
    return this.TIRE_CONDITIONS.find((item: any) => item.value === value).key;
  }

  getHoursDriven(value: any) {
    return this.HOURS_DRIVEN.find((item: any) => item.value === value).key;
  }

  getDaysForSell(value: any) {
    return this.DAYS_FOR_SELL.find((item: any) => item.value === value).key;
  }
}