import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { ProductService } from '../../product.service';
import { NewProductRequestComponent } from '../new-product-request/new-product-request.component';

@Component({
  selector: 'app-new-product-details',
  templateUrl: './new-product-details.component.html',
  styleUrls: ['./new-product-details.component.scss']
})
export class NewproductDetailsComponent implements OnInit {
  dataElement: any;

  constructor(
    private modalService: NgbModal,
    public _dataService: ProductService,
    private _utilityService: UtilityService,
    public _fileUploadService: FileUploadService,
  ) { }

  ngOnInit(): void {
    this.dataElement = this._dataService.data;
  }

  sendRequest(data: any) {
    const modalRef = this.modalService.open(NewProductRequestComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg'
    });
    modalRef.componentInstance.data = data;

    modalRef.result.then((result) => {
      if (result) {

      }
    }, (reason) => {
      console.log('Err!', reason);
    });
  }

}
