import { Component, Input, inject } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TractorService } from '../../tractor.service';

@Component({
  selector: 'app-buy-used-tractors-request',
  templateUrl: './buy-used-tractors-request.component.html',
  styleUrls: ['./buy-used-tractors-request.component.scss']
})
export class BuyUsedTractorsRequestComponent {

  modal = inject(NgbActiveModal);

  @Input() data: any;

  formGroup!: FormGroup;

  constructor(
    private _dataService: TractorService
  ) {
    this.formGroup = this._dataService.createFormForUsedTractorRequest();
  }

  onSubmit() {
    const data = this.formGroup.getRawValue();
    this._dataService.sendUsedTractorRequest(data, this.data.id, this.modal);
  }
}
