import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Pagination } from '../interfaces/pagination.interface';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    apiUrl: string = environment.apiUrl;
    pagination: Pagination = { totalPages: 0, totalCount: 0, currentPage: 1, perPage: 15, data: [] };
    defaultUrlImage: string = 'assets/images/dummy/user.png';
    PAGE_SIZE: number[] = [5, 10, 25, 50];

    constructor(
    ) { }

    /**
     * Get page number
     * 
     * @param index 
     * @param perPage 
     * @param currentPage 
     * @returns 
     */
    getPageNumber(index: number, perPage: number, currentPage: number): Number {
        return (index + 1) + ((currentPage - 1) * perPage);
    }
}
