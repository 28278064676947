<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>New Implements</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>New Implements</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="row p-0 m-0 mt-4 overflow-hidden">
    <div class="col-lg-3 p-3">
        <div class="filter-container">
            <div class="col-12 d-flex mb-2">
                <button id="reset-filter" type="button" class=" p-2 border rounded-0 w-50 btn">Apply Filter</button>
                <button id="reset-filter2" type="button" class="border rounded-0 w-50 btn p-2 ">Reset</button>
            </div>
            <div class="row">
                <div class="col-12">
                    <h6 class="filter-brand-heading py-2 shadow-sm px-1">Brands</h6>
                    <div class="filter-option-section">
                        <ng-container *ngFor="let element of brands">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="1">
                                <label class="w-100 form-check-label" for="1">
                                    {{element.name}}
                                </label>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="col-12">
                    <h6 class="filter-brand-heading py-2 shadow-sm px-1">Categories</h6>
                    <div class="filter-option-section">
                        <ng-container *ngFor="let element of categories">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="1">
                                <label class="w-100 form-check-label" for="1">
                                    {{element.name}}
                                </label>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="col-12">
                    <h6 class="filter-brand-heading py-2 shadow-sm px-1">Types</h6>
                    <div class="filter-option-section">
                        <ng-container *ngFor="let element of types">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="1">
                                <label class="w-100 form-check-label" for="1">
                                    {{element.name}}
                                </label>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-lg-9">
        <div class="col-lg-12 p-3">
            <div class="woocommerce-topbar rounded">
                <div class="row align-items-center p-1 bg-secondary px-0">
                    <div class="col-lg-9 col-md-7 col-sm-6">
                        <span class="text-white">Total Implements: {{pagination?.totalCount}}</span>
                    </div>
                    <div class="col-lg-3 col-md-5 col-sm-6 px-1">
                        <!-- <div class="woocommerce-topbar-ordering">
                            <select class=" p-2">
                                <option value="1">Default sorting</option>
                                <option value="2">Sort by popularity</option>
                                <option value="0">Sort by average rating</option>
                                <option value="3">Sort by latest</option>
                                <option value="4">Sort by price: low to high</option>
                                <option value="5">Sort by price: high to low</option>
                                <option value="6">Sort by new</option>
                            </select>
                        </div> -->
                    </div>
                </div>
            </div>

            <ng-container *ngIf="pagination?.data?.length">
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let element of pagination.data">
                        <div class="single-product-box">
                            <div class="product-image">
                                <a [routerLink]="'/implements/' + element.abbreviation" class="d-block">
                                    <img src="assets/images/loading.gif"
                                        [attr.data-src]="_fileUploadService.getFileUrl(element.images[0].originalUrl,'media')"
                                        [alt]="element.title" (error)="_fileUploadService.onImgError($event, 'media')"
                                        appLazyLoad />
                                </a>
                            </div>
                            <div class="product-content p-3">
                                <h3><a [routerLink]="'/implements/' + element.abbreviation">{{element.name |
                                        titlecase}}</a></h3>

                                <!-- <div class="price">
                                    <span class="new">{{element.newprice}}</span>
                                    <span class="old mx-2">{{element.oldprice}}</span>
                                </div> -->
                                <div class="power mt-2 d-flex align-item-center gap-2 justify-content-center">
                                    <p>By {{element.brandName[0]}}</p>
                                    <!-- <br>
                                    <p> {{element.categoryName[0]}}</p>
                                    <br>
                                    <p> {{element.typeName[0]}}</p> -->
                                </div>
                            </div>
                            <div class="view_button w-100">
                                <button type="button" class="w-100 p-2">Power : {{element.power}} HP</button>
                            </div>
                        </div>
                    </div>
                </div>
                <app-pagination *ngIf="pagination.totalPages > 1" [pagination]="pagination"
                    (outputFile)="getPagination($event)"></app-pagination>
            </ng-container>
        </div>
    </div>
</section>

<section class="update-section inner-update-section ptb-100">
    <div class="container">
        <div class="update-title">
            <h3>Get Every Single Update</h3>
        </div>

        <form class="update-form">
            <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
            <button type="submit">Subscribe Newsletter</button>
        </form>
    </div>

    <div class="shape"><img src="assets/images/shape/shape19.png" alt="Images"></div>
    <div class="shape-2"><img src="assets/images/shape/shape20.png" alt="Images"></div>
</section>