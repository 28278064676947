import { Component, OnInit } from '@angular/core';
import { MediaService } from '../../pages/media/media.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  mediaCategoryList: any[] = [];

  constructor(
    private _mediaService: MediaService
  ) {
    _mediaService.categories.subscribe((response: any) => {
      if (response) {
        this.mediaCategoryList = response
      };
    })
  }

  ngOnInit(): void {
  }

}
