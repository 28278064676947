//Dev
// export const config = {
//     apiUrl: 'http://localhost:9090/api/v1/'
// }

//Prod
export const config = {
    // apiUrl: 'http://62.72.58.127:8080/api/v1/',
    apiUrl: 'api/v1/',
    // apiUrl: 'https://agriitsolutions.com/api/v1/'
}