import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    constructor(private _httpClient: HttpClient) { }

    private sendRequest(method: string, apiUrl: string, body?: any): Observable<any> {
        const url = `${environment.apiUrl}${apiUrl}`;
        switch (method) {
            case 'POST':
                return this._httpClient.post(url, body);
            case 'POST_BLOB':
                return this._httpClient.post(url, body, {
                    responseType: 'blob',
                    observe: 'response'
                });
            case 'PATCH':
                return this._httpClient.patch(url, body);
            case 'PUT':
                return this._httpClient.put(url, body);
            case 'GET':
                return this._httpClient.get(url);
            case 'DELETE':
                return this._httpClient.delete(url);
            default:
                throw new Error('Invalid HTTP method');
        }
    }

    private processRequest(method: string, apiUrl: string, body?: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.sendRequest(method, apiUrl, body).subscribe(
                (response: any) => resolve(response),
                (error: any) => reject(error)
            );
        });
    }

    post(data: any, apiUrl: string): Promise<any> {
        return this.processRequest('POST', apiUrl, data);
    }

    patch(data: any, apiUrl: string): Promise<any> {
        return this.processRequest('PATCH', apiUrl, data);
    }

    put(data: any, apiUrl: string): Promise<any> {
        return this.processRequest('PUT', apiUrl, data);
    }

    get(apiUrl: string): Promise<any> {
        return this.processRequest('GET', apiUrl);
    }

    delete(apiUrl: string): Promise<any> {
        return this.processRequest('DELETE', apiUrl);
    }

    postWithBlob(data: any, apiUrl: string): Promise<any> {
        return this.processRequest('POST_BLOB', apiUrl, data);
    }
}
