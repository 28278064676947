import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewTractorsRequestComponent } from '../../pages/tractors/new-tractors/new-tractors-request/new-tractors-request.component';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';

@Component({
  selector: 'app-single-tractor',
  templateUrl: './single-tractor.component.html',
  styleUrls: ['./single-tractor.component.scss']
})
export class SingleTractorComponent {

  @Input("data") data!: any;
  @Output() outputFile = new EventEmitter();

  constructor(
    private modalService: NgbModal,
    public _fileUploadService: FileUploadService
  ) {

  }

  sendRequest(data: any) {
    const modalRef = this.modalService.open(NewTractorsRequestComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg' // Set the size to large (lg)
    });
    modalRef.componentInstance.data = data;

    modalRef.result.then((result) => {
      if (result) {
      }
    }, (reason) => {
      console.log('Err!', reason);
    });
  }

}
