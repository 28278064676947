import { Component, OnInit } from '@angular/core';
import { ApiResponse } from 'src/app/shared/interfaces/api-response.interface';
import { ConstantService } from 'src/app/shared/services/constant.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { TractorService } from '../tractors/tractor.service';
import { ProductService } from '../products/product.service';

@Component({
  selector: 'app-saas-home',
  templateUrl: './saas-home.component.html',
  styleUrls: ['./saas-home.component.scss']
})
export class SaasHomeComponent implements OnInit {
  tractorList: any[] = [];
  content: any;

  constructor(
    public _dataService: TractorService,
    private _loadingService: LoadingService,
    public _fileUploadService: FileUploadService,
    public _constantService: ConstantService,
    private _productService: ProductService
  ) {
    _productService.banners.subscribe((response: any) => {
      this.content = response;
    })
  }

  ngOnInit(): void {
    this.getTractorList();
  }

  async getTractorList() {
    try {
      this._loadingService.loading.next(true);
      const response: ApiResponse = await this._dataService.searchNewTractors(
        {
          brand: null,
          price: null,
          hp: null
        },
        4,
        1,
        null,
        null
      );

      this.tractorList = response.data.data;
    } catch (error) {
      console.error(error);
    } finally {
      this._loadingService.loading.next(false);
    }
  }

}
