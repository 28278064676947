import { Component, OnInit } from '@angular/core';
import { MediaService } from '../../pages/media/media.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  mediaCategoryList: any[] = [];

  constructor(
    private _mediaService: MediaService
  ) {
    _mediaService.categories.subscribe((response: any) => {
      this.mediaCategoryList = response;
    })
  }

  ngOnInit(): void {
  }

}
