import { Component, OnInit } from '@angular/core';
import { MediaService } from '../media.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { ApiResponse } from 'src/app/shared/interfaces/api-response.interface';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-media-details',
  templateUrl: './media-details.component.html',
  styleUrls: ['./media-details.component.scss']
})
export class MediaDetailsComponent implements OnInit {
  dataElement: any;
  mediaCategoryList: any[] = [];
  latestDataList: any[] = [];
  category: any;

  constructor(
    private _mediaService: MediaService,
    public _fileUploadService: FileUploadService,
    private _activatedRoute: ActivatedRoute
  ) {
    _mediaService.categories.subscribe((response: any) => {
      if (response) {
        this.mediaCategoryList = response
      }
    })

    _mediaService.getLatestData().then((response: ApiResponse) => {
      this.latestDataList = response.data;
    })

    this._activatedRoute.params.subscribe(params => {
      this.category = params['category'];
      _mediaService.getDataByAbbreviation(params['abbreviation']).then((response: ApiResponse) => {
        this.dataElement = response.data
      })
    });
  }

  ngOnInit(): void {
    if (this._mediaService.data) {
      this.dataElement = this._mediaService.data;
    }
  }

}
