<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>News & Updates</h2>

                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>News & Updates</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-details-area ptb-100">
    <div class="container" *ngIf="dataElement">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img src="assets/images/loading.gif"
                            [attr.data-src]="_fileUploadService.getFileUrl(dataElement.bannerImage.originalUrl,'media')"
                            [alt]="dataElement.title" (error)="_fileUploadService.onImgError($event, 'media')"
                            appLazyLoad />
                    </div>

                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li><span>Posted On:</span> <a routerLink="/">{{dataElement.createdAt | date}}</a></li>
                                <li><span>Posted By:</span> <a routerLink="/">Agri</a></li>
                            </ul>
                        </div>

                        <h3>{{dataElement.title}}</h3>

                        <div [innerHTML]="dataElement.description"></div>

                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget_search">
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="fas fa-search"></i></button>
                        </form>
                    </section>

                    <section class="widget widget_zash_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>

                        <ng-container *ngFor="let element of latestDataList">
                            <article class="item">
                                <a [routerLink]="'/news-and-updates/'+category+'/' + element.abbreviation"
                                    class="thumb">
                                    <img class="fullimage cover" src="assets/images/loading.gif"
                                        [attr.data-src]="_fileUploadService.getFileUrl(dataElement.bannerImage.originalUrl,'media')"
                                        [alt]="dataElement.title"
                                        (error)="_fileUploadService.onImgError($event, 'media')" appLazyLoad />
                                </a>
                                <div class="info">
                                    <time class="2024-06-30">{{element.createdAt | dateTime}}</time>
                                    <h4 class="title usmall">
                                        <a
                                            [routerLink]="'/news-and-updates/'+category+'/' + element.abbreviation">{{element.title}}</a>
                                    </h4>
                                </div>
                            </article>
                        </ng-container>
                    </section>

                    <section class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>

                        <ul>
                            <ng-container *ngFor="let element of mediaCategoryList">
                                <li>
                                    <a [routerLink]="'/news-and-updates/' + element.abbreviation">{{element.name |
                                        titlecase}}</a>
                                </li>
                            </ng-container>
                        </ul>
                    </section>

                    <!-- <section class="widget widget_recent_comments">
                        <h3 class="widget-title">Recent Comments</h3>

                        <ul>
                            <li><span class="comment-author-link"><a routerLink="/">A WordPress Commenter</a></span> on <a routerLink="/">Hello world!</a></li>
                            <li><span class="comment-author-link"><a routerLink="/">Exto</a></span> on <a routerLink="/">Hello world!</a></li>
                            <li><span class="comment-author-link"><a routerLink="/">Wordpress</a></span> on <a routerLink="/">Hello world!</a></li>
                            <li><span class="comment-author-link"><a routerLink="/">A WordPress Commenter</a></span> on <a routerLink="/">Hello world!</a></li>
                            <li><span class="comment-author-link"><a routerLink="/">Exto</a></span> on <a routerLink="/">Hello world!</a></li>
                        </ul>
                    </section> -->

                    <!-- <section class="widget widget_recent_entries">
                        <h3 class="widget-title">Recent Posts</h3>

                        <ul>
                            <li><a routerLink="/blog-details">How to Become a Successful Entry Level UX Designer</a></li>
                            <li><a routerLink="/blog-details">How to start your business as an entrepreneur</a></li>
                            <li><a routerLink="/blog-details">How to be a successful entrepreneur</a></li>
                            <li><a routerLink="/blog-details">10 Building Mobile Apps With Ionic And React</a></li>
                            <li><a routerLink="/blog-details">Protect your workplace from cyber attacks</a></li>
                        </ul>
                    </section> -->

                    <!-- <section class="widget widget_archive">
                        <h3 class="widget-title">Archives</h3>

                        <ul>
                            <li><a routerLink="/">May 2024</a></li>
                            <li><a routerLink="/">April 2024</a></li>
                            <li><a routerLink="/">June 2024</a></li>
                        </ul>
                    </section> -->

                    <!-- <section class="widget widget_meta">
                        <h3 class="widget-title">Meta</h3>

                        <ul>
                            <li><a routerLink="/">Log in</a></li>
                            <li><a routerLink="/">Entries <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                            <li><a routerLink="/">Comments <abbr title="Really Simple Syndication">RSS</abbr></a></li>
                            <li><a routerLink="/">WordPress.org</a></li>
                        </ul>
                    </section> -->

                    <!-- <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Tags</h3>
                        
                        <div class="tagcloud">
                            <a routerLink="/">IT <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Exto <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/">Games <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </section> -->
                </aside>
            </div>
        </div>
    </div>
</section>