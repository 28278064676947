<footer class="footer-section">
    <div class="container">
        <div class="row">
            <!-- <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="footer-area-content">
                    <img class="w-75" src="../../../../assets/images/tractor/default-image (1).webp" alt="image">
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing. Asperiores soluta est eum corporis laborum aliquid, harum vero! Assumenda sit exercitationem in sapiente optio illo non autem exercitationem</p>
                </div>
            </div> -->

            <div class="col-lg-2 col-md-4 col-sm-6 ">
                <div class="single-footer-widget">
                    <h3>IMPORTANT LINKS</h3>

                    <ul class="footer-quick-links">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/"> About Us</a></li>
                        <li><a routerLink="/">Contact / Mail Us</a></li>
                        <!-- <li><a routerLink="/">Careers</a></li> -->
                        <!-- <li><a routerLink="/">Customer Care</a></li> -->
                        <!-- <li><a routerLink="/">Service Centers</a></li> -->
                        <li><a routerLink="/">Loan</a></li>
                        <!-- <li><a routerLink="/">Guest Post</a></li> -->
                        <!-- <li><a routerLink="/">Tractor Dealers</a></li> -->
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="single-footer-widget">
                    <h3>EXPLORE NEWS</h3>

                    <ul class="footer-quick-links">
                        <ng-container *ngFor="let element of mediaCategoryList">
                            <li>
                                <a [routerLink]="'/news-and-updates/' + element.abbreviation">{{element.name |
                                    titlecase}}</a>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-4 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Buy Used</h3>

                    <ul class="footer-quick-links">
                        <li><a routerLink="/buy-used-tractors">Buy Used Tractor</a></li>
                        <li><a routerLink="/buy-used-implements">Buy Used Farm Implements</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Sell Used</h3>

                    <ul class="footer-quick-links">
                        <li><a routerLink="/sell-used-tractors">Sell Used Tractor</a></li>
                        <li><a routerLink="/sell-used-implements">Sell Used Farm Implements</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Contact US</h3>

                    <ul class="footer-quick-links address-link">
                        <li><a href="#"><i class="fa fa-map-marker"></i> Jaipur – Jhunjhunu Bai pass Road Near Skoda
                                Showroom
                                Agriit solution Sikar 332001</a></li>
                        <li><a href="mailto:agriitsolutionsdevelopment&#64;gmail.com"><i class="fa fa-envelope"></i>
                                agriitsolutionsdevelopment&#64;gmail.com</a>
                        </li>
                        <li><a href="tel:+919610450473"><i class="fa fa-phone"></i> +91 96104 50473, +91 93527 69460</a>
                        </li>
                    </ul>

                    <ul class="social-list">
                        <li><a href="#" class="facebook" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                        <li><a href="#" class="twitter" target="_blank"><i class="fab fa-twitter"></i></a></li>
                        <li><a href="#" class="linkedin" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        <li><a href="#" class="instagram" target="_blank"><i class="fab fa-instagram"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>© 2024 Agriit Solution. All Rights Reserved.<a href="#" target="_blank">Manish Saini</a></p>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class="fas fa-chevron-up"></i>
    <i class="fas fa-chevron-up"></i>
</div>

<!-- <div class="sidebar-modal">  
    <div class="modal right fade" id="myModal2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel2">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">
                            <i class="fa fa-times"></i>
                        </span>
                    </button>

                    <h2 class="modal-title" id="myModalLabel2">
                        <a routerLink="/">
                            <img src="assets/images/logo/logo.png" alt="Logo">
                        </a>
                    </h2>
                </div>

                <div class="modal-body">
                    <div class="sidebar-modal-widget">
                        <h3 class="title">Welcome To Ervy</h3>
                        <img src="assets/images/about.jpg" alt="">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo dolore saepe exercitationem alias unde, blanditiis non error</p>
                    </div>

                    <div class="sidebar-modal-widget">
                        <h3 class="title">Contact Info</h3>

                        <ul class="contact-info">
                            <li>
                                <i class="fa fa-map-marker"></i>
                                Address
                                <span>121 King St, VIC 3000, Australia</span>
                            </li>

                            <li>
                                <i class="fa fa-phone"></i>
                                Email
                                <span>hello&#64;ervy.com</span>
                            </li>

                            <li>
                                <i class="fa fa-envelope"></i>
                                Phone
                                <span>+098-123-23455</span>
                            </li>
                        </ul>
                    </div>
                    
                    <div class="sidebar-modal-widget">
                        <h3 class="title">Connect With Us</h3>

                        <ul class="social-list">
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->