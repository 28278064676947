<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Tractor Details</h2>

                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Tractor Details</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="product-details-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="product-details-image">
                    <h3>{{dataElement.name}}</h3>
                    <carousel [isAnimated]="true">
                        <ng-container *ngFor="let element of dataElement.images">
                            <slide>
                                <img src="assets/images/loading.gif" style="display: block; width: 100%;"
                                    [attr.data-src]="_fileUploadService.getFileUrl(element.originalUrl,'media')"
                                    [alt]="element.title" (error)="_fileUploadService.onImgError($event, 'media')"
                                    appLazyLoad />
                            </slide>
                        </ng-container>
                    </carousel>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="product-details-desc">
                    <h3></h3>

                    <!-- <div class="product-review">
                        <div class="rating">
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star"></i>
                            <i class="fas fa-star-half-alt"></i>
                        </div>
                        <a routerLink="/" class="rating-count">3 reviews</a>
                        <button routerLink="/" class="border-0 mx-2">COMPARE</button>
                    </div> -->
                    <div>
                        <table class="w-100">

                            <tr>
                                <td class="fw-bold">Brand</td>
                                <td>{{dataElement.brandName}}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">No. Of Cylinder</td>
                                <td>{{dataElement.noOfCylinder}}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">HP Category</td>
                                <td>{{dataElement.hpCategory}}HP</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">PTO HP</td>
                                <td>{{dataElement.ptoHp}}HP</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Gear Box</td>
                                <td>{{dataElement.gearBox}}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Brakes</td>
                                <td>{{dataElement.brakes}}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Warranty</td>
                                <td>{{dataElement.warranty}}</td>
                            </tr>
                            <tr>
                                <td class="fw-bold">Price</td>
                                <td id="table_price">{{dataElement.price}}
                                    <!-- <div>
                                        <a href="'/'">EMI starts from ₹21,304*</a>
                                    </div> -->
                                </td>
                            </tr>
                        </table>

                    </div>

                    <div class="buy-checkbox-btn">
                        <div class="item d-flex gap-2">
                            <a (click)="sendRequest(dataElement)" class="border btn btn-light">Check Tractor Price</a>
                            <!-- <a routerLink="/" class="border btn btn-light">View Loan Offer</a> -->

                        </div>
                    </div>
                </div>
            </div>
            <br>
            <hr>

            <h4>{{dataElement.name}} Other Features</h4>

            <div class="features-container">
                <div class="feature">
                    <img src="assets/images/tools/pto_hp.svg" alt="PTO HP Icon">
                    <div class="feature-title">{{dataElement.ptoHp}} Hp</div>
                    <div class="feature-description">PTO HP</div>
                </div>
                <div class="feature">
                    <img src="assets/images/tools/gear_box.svg" alt="Gear Box Icon">
                    <div class="feature-title">{{dataElement.gearBox}}</div>
                    <div class="feature-description">Gear Box</div>
                </div>
                <div class="feature">
                    <img src="assets/images/tools/Brakes.svg" alt="Brakes Icon">
                    <div class="feature-title">{{dataElement.brakes}}</div>
                    <div class="feature-description">Brakes</div>
                </div>
                <div class="feature">
                    <img src="assets/images/tools/Warranty.svg" alt="Warranty Icon">
                    <div class="feature-title">{{dataElement.warranty}} Years</div>
                    <div class="feature-description">Warranty</div>
                </div>
                <div class="feature">
                    <img src="assets/images/tools/clutch.svg" alt="Clutch Icon">
                    <div class="feature-title">{{dataElement.clutch}}</div>
                    <div class="feature-description">Clutch</div>
                </div>
                <div class="feature">
                    <img src="assets/images/tools/steering-wheel-solid.svg" alt="Steering Icon">
                    <div class="feature-title">{{dataElement.steering}}</div>
                    <div class="feature-description">Steering</div>
                </div>
                <div class="feature">
                    <img src="assets/images/tools/lifting_capacity.svg" alt="Lifting Capacity Icon">
                    <div class="feature-title">{{dataElement.liftingCapacity}} Kg</div>
                    <div class="feature-description">Lifting Capacity</div>
                </div>
                <div class="feature">
                    <img src="assets/images/tools/wheelDrive-features.svg" alt="Wheel Drive Icon">
                    <div class="feature-title">{{dataElement.wheelDrive}} WD</div>
                    <div class="feature-description">Wheel drive</div>
                </div>
                <div class="feature">
                    <img src="assets/images/tools/rpm.svg" alt="Engine RPM Icon">
                    <div class="feature-title">{{dataElement.engineRatedRpm}}</div>
                    <div class="feature-description">Engine Rated RPM</div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="tab products-details-tab">
                    <ul class="tabs">
                        <li>
                            <a href="#">
                                <div class="dot"></div>
                                Description
                            </a>
                        </li>
                        <!-- <li>
                            <a href="#">
                                <div class="dot"></div>
                                Additional information
                            </a>
                        </li>
                        <li>
                            <a href="#">
                                <div class="dot"></div>
                                Reviews
                            </a>
                        </li> -->
                    </ul>

                    <div class="tab_content">
                        <div class="tabs_item">
                            <div class="products-details-tab-content">
                                <div [innerHTML]="dataElement.description"></div>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="products-details-tab-content">
                                <ul class="additional-information">
                                    <li><span>Brand:</span> ThemeForest</li>
                                    <li><span>Color:</span> Brown</li>
                                    <li><span>Size:</span> Large, Medium</li>
                                    <li><span>Weight:</span> 27 kg</li>
                                    <li><span>Dimensions:</span> 16 x 22 x 123 cm</li>
                                </ul>
                            </div>
                        </div>

                        <div class="tabs_item">
                            <div class="products-details-tab-content">
                                <div class="product-review-form">
                                    <h3>Customer Reviews</h3>

                                    <div class="review-title">
                                        <div class="rating">
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="far fa-star"></i>
                                        </div>
                                        <p>Based on 3 reviews</p>
                                        <a href="#" class="default-btn">Write a Review</a>
                                    </div>

                                    <div class="review-comments">
                                        <div class="review-item">
                                            <div class="rating">
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="far fa-star"></i>
                                            </div>
                                            <h3>Good</h3>
                                            <span><strong>Admin</strong> on <strong>Sep 21, 2024</strong></span>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                                veniam, quis nostrud exercitation.</p>
                                            <a href="#" class="review-report-link">Report as Inappropriate</a>
                                        </div>

                                        <div class="review-item">
                                            <div class="rating">
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="far fa-star"></i>
                                            </div>
                                            <h3>Good</h3>
                                            <span><strong>Admin</strong> on <strong>Sep 21, 2024</strong></span>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                                veniam, quis nostrud exercitation.</p>
                                            <a href="#" class="review-report-link">Report as Inappropriate</a>
                                        </div>

                                        <div class="review-item">
                                            <div class="rating">
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="fas fa-star"></i>
                                                <i class="far fa-star"></i>
                                            </div>
                                            <h3>Good</h3>
                                            <span><strong>Admin</strong> on <strong>Sep 21, 2024</strong></span>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                                tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                                                veniam, quis nostrud exercitation.</p>
                                            <a href="#" class="review-report-link">Report as Inappropriate</a>
                                        </div>
                                    </div>

                                    <div class="review-form">
                                        <h3>Write a Review</h3>

                                        <form>
                                            <div class="row">
                                                <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">
                                                        <input type="text" id="name" name="name"
                                                            placeholder="Enter your name" class="form-control">
                                                    </div>
                                                </div>

                                                <div class="col-lg-6 col-md-6">
                                                    <div class="form-group">
                                                        <input type="email" id="email" name="email"
                                                            placeholder="Enter your email" class="form-control">
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <input type="text" id="review-title" name="review-title"
                                                            placeholder="Enter your review a title"
                                                            class="form-control">
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-12">
                                                    <div class="form-group">
                                                        <textarea name="review-body" id="review-body" cols="30" rows="7"
                                                            placeholder="Write your comments here"
                                                            class="form-control"></textarea>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-12">
                                                    <button type="submit" class="default-btn">Submit Review</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- <div class="related-products">
        <div class="container">
            <div class="products-title">
                <span class="sub-title">Our Shop</span>
                <h2>Related Products</h2>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-product-box">
                        <div class="product-image">
                            <a routerLink="/products-details" class="d-block"><img
                                    src="assets/images/tractor/mahindra-arjun-novo-605-dii-4wd-1698917936.webp"
                                    alt="image"></a>

                            <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i
                                    class="flaticon-shopping-cart"></i></a>
                        </div>

                        <div class="product-content">
                            <h3><a routerLink="/products-details">Novel Bunch</a></h3>

                            <div class="price">
                                <span class="new">$455.50</span>
                                <span class="old">$460.50</span>
                            </div>

                            <div class="rating">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-product-box">
                        <div class="product-image">
                            <a routerLink="/products-details" class="d-block"><img
                                    src="assets/images/tractor/swaraj-855-fe-1694259363.webp" alt="image"></a>

                            <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i
                                    class="flaticon-shopping-cart"></i></a>
                        </div>

                        <div class="product-content">
                            <h3><a routerLink="/products-details">Book Chicks</a></h3>

                            <div class="price">
                                <span class="new">$541.50</span>
                                <span class="old">$652.50</span>
                            </div>

                            <div class="rating">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star-half-alt"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3">
                    <div class="single-product-box">
                        <div class="product-image">
                            <a routerLink="/products-details" class="d-block"><img
                                    src="assets/images/tractor/mahindra-arjun-novo-605-dii-4wd-1698917936.webp"
                                    alt="image"></a>

                            <a routerLink="/cart" class="add-to-cart-btn">Add To Cart <i
                                    class="flaticon-shopping-cart"></i></a>
                        </div>

                        <div class="product-content">
                            <h3><a routerLink="/products-details">Book Divas</a></h3>

                            <div class="price">
                                <span class="new">$140.50</span>
                                <span class="old">$150.50</span>
                            </div>

                            <div class="rating">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="far fa-star"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</section>