import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { API_CONSTANTS } from 'src/app/shared/config/api-config';
import { ApiService } from 'src/app/shared/services/api.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { ToastService } from 'src/app/shared/services/toast.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Pagination } from 'src/app/shared/interfaces/pagination.interface';

@Injectable({
    providedIn: 'root'
})
export class ProductService implements Resolve<any> {

    brands: BehaviorSubject<any> = new BehaviorSubject([]);
    categories: BehaviorSubject<any> = new BehaviorSubject([]);
    types: BehaviorSubject<any> = new BehaviorSubject([]);
    banners: BehaviorSubject<any> = new BehaviorSubject([]);

    onDataChanged: BehaviorSubject<any> = new BehaviorSubject({});
    onDataListChanged: BehaviorSubject<Pagination> = new BehaviorSubject<Pagination>({} as Pagination);
    data: any = null;
    routeParams: any = null;
    state: string = "";

    /**
     * Initialize constructor
     * 
     * @param _utilityService 
     * @param _apiService 
     * @param _formBuilder 
     * @param _loadingService 
     * @param _router 
     * @param _toastService 
     */
    constructor(
        public _utilityService: UtilityService,
        private _apiService: ApiService,
        private _formBuilder: FormBuilder,
        private _loadingService: LoadingService,
        private _toastService: ToastService,
        private _router: Router
    ) { }

    /**
    * Resolve
    * 
    * @param route 
    * @param state 
    * @returns 
    */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        this.routeParams = route.params;
        this.state = state.url;
        return new Promise((resolve, reject) => {
            Promise.all([
                this.getDataForResolve()
            ]).then(
                () => resolve(0),
                reject
            );
        });
    }

    /**
     * Get data for resolve
     * 
     * @returns 
     */
    getDataForResolve(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.routeParams.type === 'new' || this.routeParams.type === 'used') {
                this.data = undefined;
                this.onDataChanged.next(null);
                resolve(false);
            } else if (this.state.includes('add')) {
                this.data = undefined;
                this.onDataChanged.next(null);
                resolve(false);
            } else if (this.state.includes('edit') && this.routeParams.id) {
                const dataRequest = this.getDataById(this.routeParams.id);
                dataRequest.then(
                    (response: any) => {
                        this.data = response.data;
                        this.onDataChanged.next(this.data);
                        resolve(response);
                    },
                    reject
                );
            } else if (this.state.includes('/implements') && this.routeParams.abbreviation) {
                const dataRequest = this.getNewImplementDetailsById(this.routeParams.abbreviation);
                dataRequest.then(
                    (response: any) => {
                        if (response.status === 'OK') {
                            this.data = response.data;
                            this.onDataChanged.next(this.data);
                            resolve(response);
                        } else {
                            reject(true);
                        }
                    },
                    reject
                );
            } else if (this.state.includes('/used-implements') && this.routeParams.abbreviation) {
                const dataRequest = this.getUsedDetailsByAbbreviation(this.routeParams.abbreviation);
                dataRequest.then(
                    (response: any) => {
                        if (response.status === 'OK') {
                            this.data = response.data;
                            this.onDataChanged.next(this.data);
                            resolve(response);
                        } else {
                            reject(true);
                        }
                    },
                    reject
                );
            }
        });
    }

    /**
     * Create form
     * 
     * @param element 
     */
    createForm(element?: any): FormGroup {
        return this._formBuilder.group({
            name: [element?.name || null, Validators.required],
            brandId: [element?.brandId || null, Validators.required],
            categoryId: [element?.categoryId || null, Validators.required],
            typeId: [element?.typeId || null, Validators.required],
            price: [element?.price || null, Validators.required],
            power: [element?.power || null, Validators.required],
            images: [element?.images || null, Validators.required],
            description: [element?.description || null, Validators.required]
        });
    }

    /**
    * Create form
    * 
    * @param element 
    */
    createFormForNewProductRequest(): FormGroup {
        return this._formBuilder.group({
            name: [null, Validators.required],
            mobileNumber: [null, Validators.required],
            state: [null, Validators.required],
            district: [null, Validators.required],
            tehsil: [null, Validators.required]
        });
    }

    /**
     * Create form
     * 
     * @param element 
     */
    createFormForUsedProductRequest(): FormGroup {
        return this._formBuilder.group({
            name: [null, Validators.required],
            mobileNumber: [null, Validators.required],
            budget: [null, Validators.required],
            location: [null, Validators.required]
        });
    }

    /**
     * Create form
     * 
     * @param element 
     */
    createSellUsedProductForm(): FormGroup {
        return this._formBuilder.group({
            step1: this._formBuilder.group({
                categoryId: [null, Validators.required],
                brandId: [null, Validators.required],
                model: [null, Validators.required],
                year: [null, Validators.required]
            }),
            step2: this._formBuilder.group({
                title: [null, Validators.required],
                price: [null, Validators.required],
                description: [null, Validators.required],
            }),
            step3: this._formBuilder.group({
                images: [null, Validators.required]
            }),
            step4: this._formBuilder.group({
                name: [null, Validators.required],
                mobileNumber: ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
                state: [null, Validators.required],
                district: [null, Validators.required],
                tehsil: [null, Validators.required],
                pincode: [null, Validators.required]
            })
        });
    }

    /**
     * Add or update data
     * 
     * @param data 
     * @param id 
     */
    async addOrUpdateData(data: any, id: any) {
        try {
            this._loadingService.loading.next(true);
            const endpoint = id === null ? API_CONSTANTS.ADD_PRODUCT : API_CONSTANTS.UPDATE_PRODUCT.replace("{id}", id);
            const response = id === null ? await this._apiService.post(data, endpoint) : await this._apiService.put(data, endpoint);
            if (response && response.status === 'OK') {
                this._loadingService.loading.next(false);
                this._toastService.success(response.message);
                this._router.navigateByUrl("/products");
            } else {
                this._toastService._handleError(response?.message);
            }
        } catch (error) {
            this._toastService._handleError(error);
        }
    }

    /**
     * Get all data
     * 
     * @returns 
     */
    getAllData(): Promise<any> {
        return this._apiService.get(API_CONSTANTS.GET_ALL_CATEGORY);
    }

    /**
     * Get data by id
     * 
     * @param id 
     */
    deleteDataById(id: any): Promise<any> {
        return this._apiService.delete(API_CONSTANTS.DELETE_CATEGORY.replace("{id}", id));
    }

    getDataList() {
        return this._apiService.get(API_CONSTANTS.GET_CATEGORY_LIST);
    }

    searchNewProducts(data: any, limit: any, page: any, sortBy?: any, orderBy?: any) {
        let endPoint = API_CONSTANTS.SEARCH_NEW_PRODUCTS;
        if (limit) {
            endPoint += `?limit=${limit}&page=${page}`;
        }
        return this._apiService.post(data, endPoint);
    }

    searchUsedProducts(data: any, limit: any, page: any, sortBy?: any, orderBy?: any) {
        let endPoint = API_CONSTANTS.SEARCH_USED_PRODUCTS;
        if (limit) {
            endPoint += `?limit=${limit}&page=${page}`;
        }
        return this._apiService.post(data, endPoint);
    }

    /**
    * Get data by id
    * 
    * @param id 
    */
    getDataById(id: any): Promise<any> {
        return this._apiService.get(API_CONSTANTS.GET_PRODUCT_BY_ID.replace("{id}", id));
    }

    /**
     * Get all brands
     * 
     * @returns 
     */
    getBrands(): Promise<any> {
        return this._apiService.get(API_CONSTANTS.GET_PRODUCT_BRANDS);
    }

    /**
     * Get all brands
     * 
     * @returns 
     */
    getCategories(): Promise<any> {
        return this._apiService.get(API_CONSTANTS.GET_PRODUCT_CATEGORIES);
    }

    /**
     * Get all brands
     * 
     * @returns 
     */
    getTypes(): Promise<any> {
        return this._apiService.get(API_CONSTANTS.GET_PRODUCT_TYPES);
    }

    getBanners(): Promise<any> {
        return this._apiService.get(API_CONSTANTS.GET_BANNERS);
    }

    getNewImplementDetailsById(abbreviation: any): Promise<any> {
        return this._apiService.get(API_CONSTANTS.GET_NEW_PRODUCT_DETAILS_BY_ID.replace("{abbreviation}", abbreviation));
    }

    getUsedDetailsByAbbreviation(abbreviation: any): Promise<any> {
        return this._apiService.get(API_CONSTANTS.GET_USED_PRODUCT_DETAILS_BY_ABBREVIATION.replace("{abbreviation}", abbreviation));
    }

    /**
     * Add or update data
     * 
     * @param data 
     * @param id 
     */
    async sendNewProductRequest(data: any, tractorId: any, modal: any) {
        try {
            this._loadingService.loading.next(true);
            const endpoint = API_CONSTANTS.NEW_PRODUCT_REQUEST.replace("{id}", tractorId);
            const response = await this._apiService.post(data, endpoint);
            if (response && response.status === 'OK') {
                this._loadingService.loading.next(false);
                this._toastService.success(response.message);
                modal.dismiss('Cross click')
            } else {
                this._toastService._handleError(response?.message);
            }
        } catch (error) {
            this._toastService._handleError(error);
        }
    }

    /**
    * Add or update data
    * 
    * @param data 
    * @param id 
    */
    async sendUsedProductRequest(data: any, productId: any, modal: any) {
        try {
            this._loadingService.loading.next(true);
            const endpoint = API_CONSTANTS.USED_PRODUCT_REQUEST.replace("{id}", productId);
            const response = await this._apiService.post(data, endpoint);
            if (response && response.status === 'OK') {
                this._loadingService.loading.next(false);
                this._toastService.success(response.message);
                modal.dismiss('Cross click')
            } else {
                this._toastService._handleError(response?.message);
            }
        } catch (error) {
            this._toastService._handleError(error);
        }
    }

    sellUsedTractor(data: any) {
        return this._apiService.post(data, API_CONSTANTS.SELL_USED_PRODUCT);
    }

}
