import { Component, OnInit } from '@angular/core';
import { ApiResponse } from 'src/app/shared/interfaces/api-response.interface';
import { ProductService } from '../product.service';
import { FormGroup } from '@angular/forms';
import { ConstantService } from 'src/app/shared/services/constant.service';

@Component({
  selector: 'app-sell-used-products',
  templateUrl: './sell-used-products.component.html',
  styleUrls: ['./sell-used-products.component.scss']
})
export class SellUsedProductsComponent implements OnInit {

  formGroup!: FormGroup;
  steps: string[] = ['Implement Info', 'Implement Condition', 'Implement Images', 'Personal Info'];
  currentStep: number = 0;
  brands: any[] = [];
  images: any[] = [];
  models: any[] = [
    { id: 1, name: "Model 1" },
    { id: 2, name: "Model 2" }
  ];
  categories: any[] = [];

  constructor(
    private _dataService: ProductService,
    public _constantService: ConstantService,
  ) {
    this.formGroup = _dataService.createSellUsedProductForm();
    _dataService.brands.subscribe((response: any) => {
      if (response) {
        this.brands = response;
      }
    })

    _dataService.categories.subscribe((response: any) => {
      if (response) {
        this.categories = response;
      }
    })
  }

  ngOnInit(): void {
  }

  nextStep() {
    if (this.currentStep < this.steps.length - 1) {
      this.currentStep++;
    }
  }

  previousStep() {
    if (this.currentStep > 0) {
      this.currentStep--;
    }
  }

  goToStep(stepIndex: number) {
    if (this.isStepAccessible(stepIndex)) {
      this.currentStep = stepIndex;
    }
  }

  isStepAccessible(stepIndex: number): boolean {
    for (let i = 0; i < stepIndex; i++) {
      if (this.formGroup.get(`step${i + 1}`).invalid) {
        return false;
      }
    }
    return true;
  }

  getIconClass(index: number): string {
    const icons = ['fa fa-info-circle', 'fa fa-list-alt', 'fa fa-image', 'fa fa-user'];
    return icons[index] || 'fa fa-circle';
  }

  onSubmit() {
    if (this.formGroup.valid) {
      const data = this.formGroup.getRawValue();
      let json = {};
      Object.keys(data).forEach((step, index) => {
        if (index !== Object.keys(data).length - 1) {
          Object.keys(data[step]).forEach(key => {
            json[key] = data[step][key];
          });
        }
      });
      json['personalInfo'] = this.formGroup.get(`step4`).getRawValue();
      this._dataService.sellUsedTractor(json).then((response: ApiResponse) => {
        if (response.status === 'OK') {
          this.formGroup.reset();
          this.images = [];
          this.currentStep = 0;
        }
      })
    }
  }

  getFiles(event: any) {
    this.images = event.files;
    this.formGroup.get(`step3`).get('images').setValue(event.files);
  }

}
