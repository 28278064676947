import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appCurrencyFormat]'
})
export class CurrencyFormatDirective {

    constructor(private el: ElementRef, private renderer: Renderer2) { }

    @HostListener('input', ['$event']) onInput(event: InputEvent): void {
        const inputElement = this.el.nativeElement as HTMLInputElement;
        let value = inputElement.value.replace(/[^0-9.]/g, ''); // Remove non-numeric and non-decimal characters

        const decimalIndex = value.indexOf('.');
        if (decimalIndex !== -1) {
            value = value.substring(0, decimalIndex + 3); // Allow up to two decimal places
        }

        const formattedValue = parseFloat(value).toLocaleString('en-IN', { style: 'currency', currency: 'INR' });

        this.renderer.setProperty(inputElement, 'value', formattedValue);
    }
}
