import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { SaasHomeComponent } from './components/pages/saas-home/saas-home.component';
import { AboutComponent } from './components/pages/about/about.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { HeaderComponent } from './components/layouts/header/header.component';
import { HttpClientModule } from '@angular/common/http';
import { NewTractorsComponent } from './components/pages/tractors/new-tractors/new-tractors.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedComponentsModule } from './shared/components/shared-components.module';
import { SharedPipesModule } from './shared/pipes/shared-pipes.module';
import { SharedDirectivesModule } from './shared/directives/shared-directives.module';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NewTractorsRequestComponent } from './components/pages/tractors/new-tractors/new-tractors-request/new-tractors-request.component';
import { NewTractorDetailsComponent } from './components/pages/tractors/new-tractors/new-tractor-details/new-tractor-details.component';
import { MediaDetailsComponent } from './components/pages/media/media-details/media-details.component';
import { MediaComponent } from './components/pages/media/media.component';
import { BuyUsedProductsComponent } from './components/pages/products/buy-used-products/buy-used-products.component';
import { NewproductDetailsComponent } from './components/pages/products/new-products/new-product-details/new-product-details.component';
import { NewProductRequestComponent } from './components/pages/products/new-products/new-product-request/new-product-request.component';
import { NewProductsComponent } from './components/pages/products/new-products/new-products.component';
import { ProductDetailsComponent } from './components/pages/products/product-details/product-details.component';
import { SellUsedProductsComponent } from './components/pages/products/sell-used-products/sell-used-products.component';
import { BuyUsedTractorDetailsComponent } from './components/pages/tractors/buy-used-tractors/buy-used-tractor-details/buy-used-tractor-details.component';
import { BuyUsedTractorsRequestComponent } from './components/pages/tractors/buy-used-tractors/buy-used-tractors-request/buy-used-tractors-request.component';
import { BuyUsedTractorsComponent } from './components/pages/tractors/buy-used-tractors/buy-used-tractors.component';
import { RegionSellUsedTractorsComponent } from './components/pages/tractors/sell-used-tractors/region-sell-used-tractors/region-sell-used-tractors.component';
import { SellUsedTractorsStepCompletedComponent } from './components/pages/tractors/sell-used-tractors/sell-used-tractors-step-completed/sell-used-tractors-step-completed.component';
import { SellUsedTractorsComponent } from './components/pages/tractors/sell-used-tractors/sell-used-tractors.component';
import { UsedProductDetailsComponent } from './components/pages/products/buy-used-products/used-product-details/used-product-details.component';
import { UsedProductRequestComponent } from './components/pages/products/buy-used-products/used-product-request/used-product-request.component';
import { SharedDataComponentsModule } from './components/shared/shared-components.module';

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    HeaderComponent,
    FooterComponent,
    SaasHomeComponent,
    AboutComponent,
    ErrorComponent,
    ComingSoonComponent,
    ContactComponent,
    NewTractorsComponent,
    SellUsedTractorsComponent,
    BuyUsedTractorsComponent,
    NewTractorDetailsComponent,
    NewProductsComponent,
    BuyUsedProductsComponent,
    SellUsedProductsComponent,
    ProductDetailsComponent,
    MediaComponent,
    MediaDetailsComponent,
    NewTractorsRequestComponent,
    BuyUsedTractorsRequestComponent,
    NewProductRequestComponent,
    NewproductDetailsComponent,
    SellUsedTractorsStepCompletedComponent,
    RegionSellUsedTractorsComponent,
    BuyUsedTractorDetailsComponent,
    UsedProductDetailsComponent,
    UsedProductRequestComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CarouselModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    SharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    NgbModalModule,
    SharedDataComponentsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
