<!-- File Upload -->
<div class="file-upload-container">
    <!-- Image Container -->
    <div class="image-container">
        <!-- Image Thumbnail -->
        <img [src]="_fileUploadService.getFileUrl(file?.originalUrl, type)" alt="" class="file-thumbnail">
        <!-- Delete Icon -->
        <a class="delete-icon" (click)="removeFile()" *ngIf="file?.originalUrl">
            <i class="feather icon-delete text-danger icon-md ms-2"></i>
        </a>
    </div>
    <br>
    <!-- File input -->
    <ng-container *ngIf="!file">
        <input type="file" (change)="onSelectFile($event)" accept="image/x-png,image/gif,image/jpeg,image/webp" class="file-input"
            id="file">
        <label for="file" class="file-label">Choose a file</label>
    </ng-container>

</div>
<!-- Progress Bar -->
<div class="progress" *ngIf="isLoading">
    <div class="progress-bar progress-bar-striped active" role="progressbar" [style.width]="progressBarForFile + '%'"
        [attr.aria-valuenow]="progressBarForFile" aria-valuemin="0" aria-valuemax="100">
        {{ progressBarForFile }}%
    </div>
</div>