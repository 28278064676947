import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TractorService } from '../../tractor.service';

@Component({
  selector: 'app-sell-used-tractors-step-completed',
  templateUrl: './sell-used-tractors-step-completed.component.html',
  styleUrls: ['./sell-used-tractors-step-completed.component.scss']
})
export class SellUsedTractorsStepCompletedComponent {

  modal = inject(NgbActiveModal);

  @Input() data: any;

  constructor(
  ) {

  }
}
