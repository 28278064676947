<div class="page-title-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>{{filter.category | titlecase}}</h2>

                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>News & Updates</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-section ptb-100">
    <div class="container">
        <ng-container *ngIf="pagination?.data?.length">
            <div class="row">
                <ng-container *ngFor="let element of pagination.data">
                    <div class="col-lg-3 col-md-6">
                        <div class="blog-item">
                            <div class="blog-image">
                                <a [routerLink]="'/news-and-updates/'+filter.category+'/' + element.abbreviation">
                                    <img src="assets/images/loading.gif"
                                        [attr.data-src]="_fileUploadService.getFileUrl(element.bannerImage.originalUrl,'media')"
                                        [alt]="element.title" (error)="_fileUploadService.onImgError($event, 'media')"
                                        appLazyLoad />
                                </a>
                            </div>

                            <div class="single-blog-item">
                                <div class="blog-content">
                                    <a [routerLink]="'/news-and-updates/'+filter.category+'/' + element.abbreviation">
                                        <h3>{{element.title}}</h3>
                                    </a>
                                    <!-- <p>Lorem ipsum dolor sit amconsectetur adipiscing elit, sed do eiusmodor.</p> -->
                                </div>

                                <ul class="blog-list">
                                    <li>{{element.createdAt |
                                        date:"MMM, dd yyyy"}}</li>
                                    <li> {{element.categoryName}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <div class="col-lg-12 col-md-12">
                    <app-pagination *ngIf="pagination.totalPages > 1" [pagination]="pagination"
                        (outputFile)="getPagination($event)"></app-pagination>
                </div>
            </div>
        </ng-container>
    </div>
</section>