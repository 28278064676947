<div class="navbar-area">
    <div class="exto-responsive-nav">
        <div class="container">
            <div class="exto-responsive-menu">
                <div class="logo">
                    <a class="navbar-brand" routerLink="/">
                        <img class="home_logo" src="assets/images/tractor/logo-removebg-preview.png" alt="logo">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="exto-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <div class="navbar_brand">
                    <a class="navbar-brand" routerLink="/">
                        <img class="home_logo" src="assets/images/tractor/logo-removebg-preview.png" alt="logo">
                    </a>
                </div>

                <div class="collapse navbar-collapse mean-menu">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a [routerLink]="['/tractors']" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact: true}">Tractors</a>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Buy Used <i class="fa fa-angle-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a [routerLink]="['/buy-used-tractors']" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Used
                                        Tractors</a></li>
                                <li class="nav-item"><a [routerLink]="['/buy-used-implements']" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Used Farm
                                        Implements</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Sell Used <i class="fa fa-angle-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a [routerLink]="['/sell-used-tractors']" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Used
                                        Tractor</a></li>
                                <li class="nav-item"><a [routerLink]="['/sell-used-implements']" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Used Farm
                                        Implements</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Farm Equipments <i
                                    class="fa fa-angle-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a [routerLink]="['/implements']" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Implements</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">News and Updates <i
                                    class="fa fa-angle-down"></i></a>
                            <ul class="dropdown-menu">
                                <ng-container *ngFor="let element of mediaCategoryList">
                                    <li class="nav-item"><a [routerLink]="['/news-and-updates', element.abbreviation]"
                                            class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">{{element.name | titlecase}}</a>
                                    </li>
                                </ng-container>
                            </ul>
                        </li>

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">More <i class="fa fa-angle-down"></i></a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a [routerLink]="['/services-details']" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Finance</a>
                                </li>
                                <li class="nav-item"><a [routerLink]="['/services-details']" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">EMI
                                        Calculator</a></li>
                                <li class="nav-item"><a [routerLink]="['/qwd']" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Tyres</a>
                                </li>
                                <li class="nav-item"><a [routerLink]="['/qqd']" class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Insurance</a></li>
                                <li class="nav-item"><a [routerLink]="['/qwd']" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Dealership
                                        Enquiry</a></li>
                                <li class="nav-item"><a [routerLink]="['/qdqw']" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Broker
                                        Dealer</a></li>
                            </ul>
                        </li> -->
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>