import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormatDateTimePipe } from './date-time.pipe';
import { CurrencyFormatPipe } from './currency-format.pipes';

const pipes: any = [
  FormatDateTimePipe,
  CurrencyFormatPipe
]

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [pipes],
  exports: [pipes]
})
export class SharedPipesModule { }
