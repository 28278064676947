<!-- File Upload -->
<div class="file-upload-container">
    <!-- File input -->
    <input type="file" (change)="onSelectFile($event)" accept="image/x-png,image/gif,image/jpeg" class="file-input"
        id="file">
    <label for="file" class="file-label">
        <i class="feather icon-paperclip"></i> Choose a file
    </label>
    <!-- Progress Bar -->
    <div class="progress" *ngIf="isLoading">
        <div class="progress-bar progress-bar-striped active" role="progressbar"
            [style.width]="progressBarForFile + '%'" [attr.aria-valuenow]="progressBarForFile" aria-valuemin="0"
            aria-valuemax="100">
            {{ progressBarForFile }}%
        </div>
    </div>
    <div class="row">
        <ng-container *ngFor="let file of files; let i = index">
            <div class="col-md-4">
                <!-- Image Container -->
                <div class="image-container">
                    <!-- Image Thumbnail -->
                    <img [src]="_fileUploadService.getFileUrl(file?.originalUrl, type)" alt="" class="file-thumbnail">
                    <!-- Delete Icon -->
                    <a class="delete-icon" (click)="removeFile(file,i)" *ngIf="file?.originalUrl">
                        <i class="feather icon-delete text-danger icon-md ms-2"></i>
                    </a>
                </div>
            </div>
        </ng-container>
    </div>
</div>