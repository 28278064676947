import { Directive, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
    selector: '[appLazyLoad]'
})
export class LazyLoadDirective implements OnInit {
    constructor(private el: ElementRef, private renderer: Renderer2) { }

    ngOnInit() {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.renderer.setAttribute(
                        this.el.nativeElement,
                        'src',
                        this.el.nativeElement.getAttribute('data-src')
                    );
                    observer.unobserve(this.el.nativeElement);
                }
            });
        });

        observer.observe(this.el.nativeElement);
    }
}
