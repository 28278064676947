import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { SharedDirectivesModule } from '../directives/shared-directives.module';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { UploadImagesComponent } from './upload-images/upload-images.component';
import { ShowImageComponent } from './show-image/show-image.component';
import { PaginationComponent } from './pagination/pagination.component';

const components = [
  UploadImageComponent,
  UploadImagesComponent,
  ShowImageComponent,
  PaginationComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedPipesModule,
    SharedDirectivesModule,
    NgbModule
  ],
  declarations: components,
  exports: components
})
export class SharedComponentsModule { }
